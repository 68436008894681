export enum Status {
  SUCCESS = 'Success',
  WAIT = 'Wait',
  FAIL = 'Fail',
}

export const statusCodes = {
  [Status.SUCCESS]: [2, 3, 4],
  [Status.WAIT]: [5, 7],
  [Status.FAIL]: [6, 8],
}

export const getStatusName = (statusCode: number): string => {
  let status = 'Fail'

  Object.entries(statusCodes).forEach(([statusName, statusCodes]) => {
    if (statusCodes.includes(statusCode)) {
      status = statusName
    }
  })

  return status
}
