// tslint:disable
/**
 * Сервис шаблонов
 * API Сервиса Шаблонов
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: ftb-support@mts.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as globalImportUrl from 'url'
import { Configuration } from './configuration'
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios'
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base'

/**
 * Запрос создания шаблона платежа
 * @export
 * @interface CreateTemplateRequest
 */
export interface CreateTemplateRequest {
  /**
   * Уникальный идентификатор пользователя
   * @type {number}
   * @memberof CreateTemplateRequest
   */
  userId: number
  /**
   * Уникальный номер операции в системе
   * @type {string}
   * @memberof CreateTemplateRequest
   */
  mdOrder?: string
  /**
   * Наименования шаблона, задается пользователем. Должно быть уникально в рамках конкретного userId. При нарушении уникальности ПЦ вернет ошибку.
   * @type {string}
   * @memberof CreateTemplateRequest
   */
  templateName: string
  /**
   * Атрибуты платежа
   * @type {number}
   * @memberof CreateTemplateRequest
   */
  serviceId?: number
  /**
   * Атрибуты платежа
   * @type {Array<ServiceParams>}
   * @memberof CreateTemplateRequest
   */
  serviceParams?: Array<ServiceParams>
  /**
   * Сумма без учёта комиссий
   * @type {number}
   * @memberof CreateTemplateRequest
   */
  amount?: number
  /**
   * Валюта заказа
   * @type {number}
   * @memberof CreateTemplateRequest
   */
  currency?: number
  /**
   * Уникальный идентификатор Платежного инструмента
   * @type {string}
   * @memberof CreateTemplateRequest
   */
  bindingId?: string
  /**
   * Набор дополнительных параметров шаблона в формате JSON
   * @type {Array<MapItem>}
   * @memberof CreateTemplateRequest
   */
  additionalParams?: Array<MapItem>
}
/**
 * Запрос отправки уведомлений по конкретному шаблону
 * @export
 * @interface CreateTemplateResponse
 */
export interface CreateTemplateResponse {
  /**
   * Уникальный идентификатор созданного шаблона
   * @type {string}
   * @memberof CreateTemplateResponse
   */
  templateId?: string
  /**
   *
   * @type {ErrorInfo}
   * @memberof CreateTemplateResponse
   */
  errorInfo?: ErrorInfo
}
/**
 * Запрос создания шаблона перевода
 * @export
 * @interface CreateTransferTemplateRequest
 */
export interface CreateTransferTemplateRequest {
  /**
   * Уникальный идентификатор пользователя
   * @type {number}
   * @memberof CreateTransferTemplateRequest
   */
  userId: number
  /**
   * Уникальный номер операции в системе
   * @type {string}
   * @memberof CreateTransferTemplateRequest
   */
  mdOrder?: string
  /**
   * Наименования шаблона, задается пользователем. Должно быть уникально в рамках конкретного userId. При нарушении уникальности ПЦ вернет ошибку.
   * @type {string}
   * @memberof CreateTransferTemplateRequest
   */
  templateName: string
  /**
   *
   * @type {TransferType}
   * @memberof CreateTransferTemplateRequest
   */
  transferType?: TransferType
  /**
   * Уникальный идентификатор Платежного инструмента, источника перевода
   * @type {string}
   * @memberof CreateTransferTemplateRequest
   */
  srcBindingId?: string
  /**
   * Номер карты, с которой осуществляется перевод
   * @type {string}
   * @memberof CreateTransferTemplateRequest
   */
  pan?: string
  /**
   * Год и месяц действия карты, с которой осуществляется перевод
   * @type {number}
   * @memberof CreateTransferTemplateRequest
   */
  expiry?: number
  /**
   * Имя держателя карты, с которой осуществляется перевод
   * @type {string}
   * @memberof CreateTransferTemplateRequest
   */
  cardholderName?: string
  /**
   * Уникальный идентификатор Платежного инструмента, получателя перевода
   * @type {string}
   * @memberof CreateTransferTemplateRequest
   */
  dstBindingId?: string
  /**
   * Номер карты, на которую осуществляется перевод. Передается только номер карты получателя, этого достаточно для переводов внутри страны
   * @type {string}
   * @memberof CreateTransferTemplateRequest
   */
  targetPan?: string
  /**
   * Номер мобильного телефона пользователя в международном формате MSISDN. Номер мобильного телефона используется для адресации счета ЭДС, получателя перевода
   * @type {number}
   * @memberof CreateTransferTemplateRequest
   */
  phone?: number
  /**
   * Сумма без учёта комиссий
   * @type {number}
   * @memberof CreateTransferTemplateRequest
   */
  amount?: number
  /**
   * Валюта заказа
   * @type {number}
   * @memberof CreateTransferTemplateRequest
   */
  currency?: number
  /**
   * Набор дополнительных параметров шаблона в формате JSON
   * @type {Array<MapItem>}
   * @memberof CreateTransferTemplateRequest
   */
  additionalParams?: Array<MapItem>
}
/**
 * Запрос на удаление шаблона
 * @export
 * @interface DeleteTemplateRequest
 */
export interface DeleteTemplateRequest {
  /**
   * Уникальный идентификатор пользователя
   * @type {number}
   * @memberof DeleteTemplateRequest
   */
  userId: number
  /**
   * Уникальный идентификатор шаблона
   * @type {string}
   * @memberof DeleteTemplateRequest
   */
  templateId: string
}
/**
 * Запрос на обновление шаблона перевода
 * @export
 * @interface DeleteTemplatesBatchRequest
 */
export interface DeleteTemplatesBatchRequest {
  /**
   * Уникальный идентификатор пользователя
   * @type {number}
   * @memberof DeleteTemplatesBatchRequest
   */
  userId: number
  /**
   * Массив идентификаторов
   * @type {Array<TamplatesList>}
   * @memberof DeleteTemplatesBatchRequest
   */
  templates?: Array<TamplatesList>
}
/**
 * Ответ на запросы с кодами
 * @export
 * @interface ErrorInfo
 */
export interface ErrorInfo {
  /**
   * Код ответа
   * @type {number}
   * @memberof ErrorInfo
   */
  errorCode: number
  /**
   * Расшифровка кода ответа
   * @type {string}
   * @memberof ErrorInfo
   */
  errorCause?: string
  /**
   * Информационное сообщение об ошибке для Пользователя
   * @type {string}
   * @memberof ErrorInfo
   */
  errorMessage?: string
}
/**
 * Запрос на получение списка шаблонов платежей пользователя
 * @export
 * @interface GetTemplatesRequest
 */
export interface GetTemplatesRequest {
  /**
   * Уникальный идентификатор пользователя
   * @type {number}
   * @memberof GetTemplatesRequest
   */
  userId: number
  /**
   * Наименование категории поставщиков
   * @type {string}
   * @memberof GetTemplatesRequest
   */
  categoryName?: string
  /**
   * Код поставщика
   * @type {number}
   * @memberof GetTemplatesRequest
   */
  serviceId?: number
  /**
   * Уникальный идентификатор Платежного инструмента
   * @type {string}
   * @memberof GetTemplatesRequest
   */
  bindingId?: string
  /**
   * Уникальный идентификатор шаблона
   * @type {string}
   * @memberof GetTemplatesRequest
   */
  templateId?: string
}
/**
 * Запрос отправки уведомлений по конкретному шаблону
 * @export
 * @interface GetTemplatesResponse
 */
export interface GetTemplatesResponse {
  /**
   * Список шаблонов, удовлетворяющих фильтру
   * @type {Array<Templates>}
   * @memberof GetTemplatesResponse
   */
  templates?: Array<Templates>
  /**
   *
   * @type {ErrorInfo}
   * @memberof GetTemplatesResponse
   */
  errorInfo?: ErrorInfo
}
/**
 * Запрос на получение списка шаблонов переводов пользователя
 * @export
 * @interface GetTransferTemplatesRequest
 */
export interface GetTransferTemplatesRequest {
  /**
   * Уникальный идентификатор пользователя
   * @type {number}
   * @memberof GetTransferTemplatesRequest
   */
  userId: number
  /**
   * Уникальный идентификатор Платежного инструмента, источника перевода
   * @type {string}
   * @memberof GetTransferTemplatesRequest
   */
  srcBindingId?: string
  /**
   * Уникальный идентификатор Платежного инструмента, получателя перевода
   * @type {string}
   * @memberof GetTransferTemplatesRequest
   */
  dstBindingId?: string
  /**
   * Уникальный идентификатор шаблона
   * @type {string}
   * @memberof GetTransferTemplatesRequest
   */
  templateId?: string
}
/**
 * Запрос отправки уведомлений по конкретному шаблону
 * @export
 * @interface GetTransferTemplatesResponse
 */
export interface GetTransferTemplatesResponse {
  /**
   * Список шаблонов, удовлетворяющих фильтру
   * @type {Array<TransferTemplates>}
   * @memberof GetTransferTemplatesResponse
   */
  templates?: Array<TransferTemplates>
  /**
   *
   * @type {ErrorInfo}
   * @memberof GetTransferTemplatesResponse
   */
  errorInfo?: ErrorInfo
}
/**
 *
 * @export
 * @interface MapItem
 */
export interface MapItem {
  /**
   *
   * @type {string}
   * @memberof MapItem
   */
  key?: string
  /**
   *
   * @type {string}
   * @memberof MapItem
   */
  value?: string
}
/**
 * Запрос на обновление шаблона перевода
 * @export
 * @interface ReplaceTemplatesBindingRequest
 */
export interface ReplaceTemplatesBindingRequest {
  /**
   * Уникальный идентификатор пользователя
   * @type {number}
   * @memberof ReplaceTemplatesBindingRequest
   */
  userId: number
  /**
   * Уникальный идентификатор Платежного инструмента
   * @type {string}
   * @memberof ReplaceTemplatesBindingRequest
   */
  bindingId: string
  /**
   * Уникальный идентификатор нового Платежного инструмента
   * @type {string}
   * @memberof ReplaceTemplatesBindingRequest
   */
  newBindingId: string
}
/**
 * Тип данных, описывающий параметры платежного инструмента
 * @export
 * @interface ServiceParams
 */
export interface ServiceParams {
  /**
   * Идентификатор параметра, уникален в рамках исполузуемого сервиса
   * @type {string}
   * @memberof ServiceParams
   */
  name: string
  /**
   * Значение параметра
   * @type {string}
   * @memberof ServiceParams
   */
  value: string
}
/**
 * Массив идентификаторов
 * @export
 * @interface TamplatesList
 */
export interface TamplatesList {
  /**
   * Уникальный идентификатор шаблона
   * @type {string}
   * @memberof TamplatesList
   */
  templateId: string
}
/**
 * Список шаблонов, удовлетворяющих фильтру
 * @export
 * @interface Templates
 */
export interface Templates {
  /**
   * Уникальный идентификатор шаблона
   * @type {string}
   * @memberof Templates
   */
  templateId: string
  /**
   * Оплата Playstation
   * @type {string}
   * @memberof Templates
   */
  templateName: string
  /**
   * Уникальный идентификатор Платежного инструмента
   * @type {string}
   * @memberof Templates
   */
  bindingId: string
  /**
   * Код поставщика
   * @type {number}
   * @memberof Templates
   */
  serviceId: number
  /**
   * Атрибуты платежа
   * @type {Array<ServiceParams>}
   * @memberof Templates
   */
  serviceParams: Array<ServiceParams>
  /**
   * Сумма без учёта комиссий
   * @type {number}
   * @memberof Templates
   */
  amount?: number
  /**
   * Валюта заказа
   * @type {number}
   * @memberof Templates
   */
  currency?: number
  /**
   * Набор дополнительных параметров шаблона в формате JSON
   * @type {Array<MapItem>}
   * @memberof Templates
   */
  additionalParams?: Array<MapItem>
}
/**
 * Список шаблонов, удовлетворяющих фильтру
 * @export
 * @interface TransferTemplates
 */
export interface TransferTemplates {
  /**
   * Уникальный идентификатор шаблона
   * @type {string}
   * @memberof TransferTemplates
   */
  templateId: string
  /**
   * Наименования шаблона, задается пользователем. Должно быть уникально в рамках конкретного userId. При нарушении уникальности ПЦ вернет ошибку.
   * @type {string}
   * @memberof TransferTemplates
   */
  templateName: string
  /**
   *
   * @type {TransferType}
   * @memberof TransferTemplates
   */
  transferType: TransferType
  /**
   * Уникальный идентификатор Платежного инструмента, источника перевода
   * @type {string}
   * @memberof TransferTemplates
   */
  srcBindingId?: string
  /**
   * Номер карты, с которой осуществляется перевод
   * @type {string}
   * @memberof TransferTemplates
   */
  pan?: string
  /**
   * Токенизированный номер карты. Для операции изменения шаблона указывается, если значение номера карты не изменялось.
   * @type {string}
   * @memberof TransferTemplates
   */
  tokenizedPan?: string
  /**
   * Токенизированный номер карты получателя. Для операции изменения шаблона указывается, если значение номера карты не изменялось.
   * @type {string}
   * @memberof TransferTemplates
   */
  tokenizedTargetPan?: string
  /**
   * Год и месяц действия карты, с которой осуществляется перевод
   * @type {number}
   * @memberof TransferTemplates
   */
  expiry?: number
  /**
   * Имя держателя карты, с которой осуществляется перевод
   * @type {string}
   * @memberof TransferTemplates
   */
  cardholderName?: string
  /**
   * Уникальный идентификатор Платежного инструмента, получателя перевода
   * @type {string}
   * @memberof TransferTemplates
   */
  dstBindingId?: string
  /**
   * Номер карты, на которую осуществляется перевод. Передается только номер карты получателя, этого достаточно для переводов внутри страны
   * @type {string}
   * @memberof TransferTemplates
   */
  targetPan?: string
  /**
   * Номер мобильного телефона пользователя в международном формате MSISDN. Номер мобильного телефона используется для адресации счета ЭДС, получателя перевода
   * @type {number}
   * @memberof TransferTemplates
   */
  phone?: number
  /**
   * Сумма без учёта комиссий
   * @type {number}
   * @memberof TransferTemplates
   */
  amount?: number
  /**
   * Валюта заказа
   * @type {number}
   * @memberof TransferTemplates
   */
  currency?: number
  /**
   * Набор дополнительных параметров шаблона в формате JSON
   * @type {Array<MapItem>}
   * @memberof TransferTemplates
   */
  additionalParams?: Array<MapItem>
}
/**
 * Тип перевода
 * @export
 * @enum {string}
 */
export enum TransferType {
  BINDINGTOBINDING = 'BINDING_TO_BINDING',
  CARDTOBINDING = 'CARD_TO_BINDING',
  CARDTOEMONEY = 'CARD_TO_EMONEY',
  BINDINGTOEMONEY = 'BINDING_TO_EMONEY',
  BINDINGTOCARD = 'BINDING_TO_CARD',
  CARDTOCARD = 'CARD_TO_CARD',
}

/**
 * Запрос на обновление шаблона платежа
 * @export
 * @interface UpdateTemplateRequest
 */
export interface UpdateTemplateRequest {
  /**
   * Уникальный идентификатор пользователя
   * @type {number}
   * @memberof UpdateTemplateRequest
   */
  userId: number
  /**
   * Уникальный идентификатор шаблона
   * @type {string}
   * @memberof UpdateTemplateRequest
   */
  templateId: string
  /**
   * Оплата Playstation
   * @type {string}
   * @memberof UpdateTemplateRequest
   */
  templateName: string
  /**
   * Код поставщика
   * @type {number}
   * @memberof UpdateTemplateRequest
   */
  serviceId: number
  /**
   * Атрибуты платежа
   * @type {Array<ServiceParams>}
   * @memberof UpdateTemplateRequest
   */
  serviceParams: Array<ServiceParams>
  /**
   * Сумма без учёта комиссий
   * @type {number}
   * @memberof UpdateTemplateRequest
   */
  amount?: number
  /**
   * Валюта заказа
   * @type {number}
   * @memberof UpdateTemplateRequest
   */
  currency?: number
  /**
   * Уникальный идентификатор Платежного инструмента
   * @type {string}
   * @memberof UpdateTemplateRequest
   */
  bindingId: string
}
/**
 * Запрос отправки уведомлений по конкретному шаблону
 * @export
 * @interface UpdateTemplateResponse
 */
export interface UpdateTemplateResponse {
  /**
   * Набор дополнительных параметров шаблона в формате JSON
   * @type {Array<MapItem>}
   * @memberof UpdateTemplateResponse
   */
  additionalParams?: Array<MapItem>
  /**
   *
   * @type {ErrorInfo}
   * @memberof UpdateTemplateResponse
   */
  errorInfo?: ErrorInfo
}
/**
 * Запрос на обновление шаблона перевода
 * @export
 * @interface UpdateTransferTemplateRequest
 */
export interface UpdateTransferTemplateRequest {
  /**
   * Уникальный идентификатор пользователя
   * @type {number}
   * @memberof UpdateTransferTemplateRequest
   */
  userId: number
  /**
   * Уникальный идентификатор шаблона
   * @type {string}
   * @memberof UpdateTransferTemplateRequest
   */
  templateId: string
  /**
   * Наименования шаблона, задается пользователем. Должно быть уникально в рамках конкретного userId. При нарушении уникальности ПЦ вернет ошибку.
   * @type {string}
   * @memberof UpdateTransferTemplateRequest
   */
  templateName: string
  /**
   *
   * @type {TransferType}
   * @memberof UpdateTransferTemplateRequest
   */
  transferType: TransferType
  /**
   * Уникальный идентификатор Платежного инструмента, источника перевода
   * @type {string}
   * @memberof UpdateTransferTemplateRequest
   */
  srcBindingId?: string
  /**
   * Номер карты, с которой осуществляется перевод
   * @type {string}
   * @memberof UpdateTransferTemplateRequest
   */
  pan?: string
  /**
   * Токенизированный номер карты. Для операции изменения шаблона указывается, если значение номера карты не изменялось.
   * @type {string}
   * @memberof UpdateTransferTemplateRequest
   */
  tokenizedPan?: string
  /**
   * Год и месяц действия карты, с которой осуществляется перевод
   * @type {number}
   * @memberof UpdateTransferTemplateRequest
   */
  expiry?: number
  /**
   * Имя держателя карты, с которой осуществляется перевод
   * @type {string}
   * @memberof UpdateTransferTemplateRequest
   */
  cardholderName?: string
  /**
   * Уникальный идентификатор Платежного инструмента, получателя перевода
   * @type {string}
   * @memberof UpdateTransferTemplateRequest
   */
  dstBindingId?: string
  /**
   * Номер карты, на которую осуществляется перевод. Передается только номер карты получателя, этого достаточно для переводов внутри страны
   * @type {string}
   * @memberof UpdateTransferTemplateRequest
   */
  targetPan?: string
  /**
   * Токенизированный номер карты получателя. Для операции изменения шаблона указывается, если значение номера карты не изменялось.
   * @type {string}
   * @memberof UpdateTransferTemplateRequest
   */
  tokenizedTargetPan?: string
  /**
   * Номер мобильного телефона пользователя в международном формате MSISDN. Номер мобильного телефона используется для адресации счета ЭДС, получателя перевода
   * @type {number}
   * @memberof UpdateTransferTemplateRequest
   */
  phone?: number
  /**
   * Сумма без учёта комиссий
   * @type {number}
   * @memberof UpdateTransferTemplateRequest
   */
  amount?: number
  /**
   * Валюта заказа
   * @type {number}
   * @memberof UpdateTransferTemplateRequest
   */
  currency?: number
  /**
   * Набор дополнительных параметров шаблона в формате JSON
   * @type {Array<MapItem>}
   * @memberof UpdateTransferTemplateRequest
   */
  additionalParams?: Array<MapItem>
}

/**
 * TemplatesApi - axios parameter creator
 * @export
 */
export const TemplatesApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Данный запрос служит для создания шаблона для последующего проведения операции без заполнения всех параметров платежа пользователем. Создание шаблона возможно двумя способами: - Создание шаблона на основании успешно проведенной операции. При этом в запросе createTemplate передает-ся mdOrder, анализируя который система формирует шаблон платежа, обогащенный атрибутами поставщика - Создание шаблона посредством прямого ввода параметров совершения платежа. При этом в запросе create-Template передаются данные по поставщику и платежный инструмент.
     * @summary Запрос создания шаблона плетежа
     * @param {CreateTemplateRequest} request Тело запроса
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createTemplate(request: CreateTemplateRequest, options: any = {}): RequestArgs {
      // verify required parameter 'request' is not null or undefined
      if (request === null || request === undefined) {
        throw new RequiredError(
          'request',
          'Required parameter request was null or undefined when calling createTemplate.'
        )
      }
      const localVarPath = `/templates/createTemplate`
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      const needsSerialization =
        typeof request !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(request !== undefined ? request : {})
        : request || ''

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Данный запрос служит для создания шаблона перевода для последующего проведения операции без заполнения всех параметров перевода пользователем. Шаблон перевода может быть создан на основании проведенного перевода, в этом случае все параметры перевода могут браться с проведенной ранее операции перевода, в этом случае в запросе указывается идентификатор ранее проведенной операции перевода.
     * @summary Запрос на создание шаблона перевода
     * @param {CreateTransferTemplateRequest} request Тело запроса
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createTransferTemplate(request: CreateTransferTemplateRequest, options: any = {}): RequestArgs {
      // verify required parameter 'request' is not null or undefined
      if (request === null || request === undefined) {
        throw new RequiredError(
          'request',
          'Required parameter request was null or undefined when calling createTransferTemplate.'
        )
      }
      const localVarPath = `/templates/createTransferTemplate`
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      const needsSerialization =
        typeof request !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(request !== undefined ? request : {})
        : request || ''

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Данный запрос служит для удаления шаблона. Применим для всех типов шаблонов.
     * @summary Запрос на удаление шаблона любого типа
     * @param {DeleteTemplateRequest} request Тело запроса
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteTemplate(request: DeleteTemplateRequest, options: any = {}): RequestArgs {
      // verify required parameter 'request' is not null or undefined
      if (request === null || request === undefined) {
        throw new RequiredError(
          'request',
          'Required parameter request was null or undefined when calling deleteTemplate.'
        )
      }
      const localVarPath = `/templates/deleteTemplate`
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      const needsSerialization =
        typeof request !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(request !== undefined ? request : {})
        : request || ''

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Запрос используется для пакетного удаления шаблонов по идентификаторам. Применим для всех типов шаблонов.
     * @summary Запрос для пакетного удаления шаблонов
     * @param {DeleteTemplatesBatchRequest} request Тело запроса
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteTemplatesBatch(request: DeleteTemplatesBatchRequest, options: any = {}): RequestArgs {
      // verify required parameter 'request' is not null or undefined
      if (request === null || request === undefined) {
        throw new RequiredError(
          'request',
          'Required parameter request was null or undefined when calling deleteTemplatesBatch.'
        )
      }
      const localVarPath = `/templates/deleteTemplatesBatch`
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      const needsSerialization =
        typeof request !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(request !== undefined ? request : {})
        : request || ''

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Данный запрос может использователься в следующих ситуациях: получение всех доступных пользователю шаблонов, получение всех доступных шаблонов платежей выбранной категории, получение всех доступных шаблонов платежей для выбранного сервиса, получение всех доступных пользователю шаблонов для определенного платежного инструмента, получение данных конкретного шаблона. Параметр bindingId может использоваться вместе с categoryName и serviceId в качестве дополнительного фильтра. Использование параметра serviceId делает ненужным использование параметра categoryName. Использование параметра templateId делает ненужным использование других фильтров.
     * @summary Запрос на получение списка шаблонов платежей пользователя
     * @param {GetTemplatesRequest} request Тело запроса
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTemplates(request: GetTemplatesRequest, options: any = {}): RequestArgs {
      // verify required parameter 'request' is not null or undefined
      if (request === null || request === undefined) {
        throw new RequiredError(
          'request',
          'Required parameter request was null or undefined when calling getTemplates.'
        )
      }
      const localVarPath = `/b/bpc/ewallet/3.4/getTemplates.do`
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      const needsSerialization =
        typeof request !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(request !== undefined ? request : {})
        : request || ''

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Данный запрос может использователься в следующих ситуациях: получение всех доступных пользователю шаблонов, получение всех доступных пользователю шаблонов для определенного платежного инструмента (рассматривается только инструмент-источник перевода), получение конкретного шаблона (в этом случае указывается templateId, bindingId опускается).
     * @summary Запрос на получение списка шаблонов переводов пользователя
     * @param {GetTransferTemplatesRequest} request Тело запроса
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTransferTemplates(request: GetTransferTemplatesRequest, options: any = {}): RequestArgs {
      // verify required parameter 'request' is not null or undefined
      if (request === null || request === undefined) {
        throw new RequiredError(
          'request',
          'Required parameter request was null or undefined when calling getTransferTemplates.'
        )
      }
      const localVarPath = `/templates/getTransferTemplates`
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      const needsSerialization =
        typeof request !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(request !== undefined ? request : {})
        : request || ''

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Запрос используется для перепривязки всех шаблонов к новому платежному инструменту, используется в слоучае удаления или истечения срока действия платежного инструмента. Применим для всех типов шаблонов
     * @summary Запрос для перепривязки всех шаблонов
     * @param {ReplaceTemplatesBindingRequest} request Тело запроса
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    replaceTemplatesBinding(request: ReplaceTemplatesBindingRequest, options: any = {}): RequestArgs {
      // verify required parameter 'request' is not null or undefined
      if (request === null || request === undefined) {
        throw new RequiredError(
          'request',
          'Required parameter request was null or undefined when calling replaceTemplatesBinding.'
        )
      }
      const localVarPath = `/templates/replaceTemplatesBinding`
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      const needsSerialization =
        typeof request !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(request !== undefined ? request : {})
        : request || ''

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Данный запрос служит для переименования и изменения параметров шаблона. Все параметры шаблона передаются целиком даже в том случае, если изменение точечное, если какой-то из параметров изменять не нужно, то передается старое значение.
     * @summary Запрос на изменение шаблона платежа
     * @param {UpdateTemplateRequest} request Тело запроса
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateTemplate(request: UpdateTemplateRequest, options: any = {}): RequestArgs {
      // verify required parameter 'request' is not null or undefined
      if (request === null || request === undefined) {
        throw new RequiredError(
          'request',
          'Required parameter request was null or undefined when calling updateTemplate.'
        )
      }
      const localVarPath = `/templates/updateTemplate`
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      const needsSerialization =
        typeof request !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(request !== undefined ? request : {})
        : request || ''

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Данный запрос служит для переименования и изменения параметров шаблона. Все параметры шаблона передаются всегда целиком, если какой-то из параметров изменять не нужно, то передается старое значение.
     * @summary Запрос на изменение шаблона перевода
     * @param {UpdateTransferTemplateRequest} request Тело запроса
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateTransferTemplate(request: UpdateTransferTemplateRequest, options: any = {}): RequestArgs {
      // verify required parameter 'request' is not null or undefined
      if (request === null || request === undefined) {
        throw new RequiredError(
          'request',
          'Required parameter request was null or undefined when calling updateTransferTemplate.'
        )
      }
      const localVarPath = `/templates/updateTransferTemplate`
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      const needsSerialization =
        typeof request !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(request !== undefined ? request : {})
        : request || ''

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * TemplatesApi - functional programming interface
 * @export
 */
export const TemplatesApiFp = function (configuration?: Configuration) {
  return {
    /**
     * Данный запрос служит для создания шаблона для последующего проведения операции без заполнения всех параметров платежа пользователем. Создание шаблона возможно двумя способами: - Создание шаблона на основании успешно проведенной операции. При этом в запросе createTemplate передает-ся mdOrder, анализируя который система формирует шаблон платежа, обогащенный атрибутами поставщика - Создание шаблона посредством прямого ввода параметров совершения платежа. При этом в запросе create-Template передаются данные по поставщику и платежный инструмент.
     * @summary Запрос создания шаблона плетежа
     * @param {CreateTemplateRequest} request Тело запроса
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createTemplate(
      request: CreateTemplateRequest,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateTemplateResponse> {
      const localVarAxiosArgs = TemplatesApiAxiosParamCreator(configuration).createTemplate(request, options)
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     * Данный запрос служит для создания шаблона перевода для последующего проведения операции без заполнения всех параметров перевода пользователем. Шаблон перевода может быть создан на основании проведенного перевода, в этом случае все параметры перевода могут браться с проведенной ранее операции перевода, в этом случае в запросе указывается идентификатор ранее проведенной операции перевода.
     * @summary Запрос на создание шаблона перевода
     * @param {CreateTransferTemplateRequest} request Тело запроса
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createTransferTemplate(
      request: CreateTransferTemplateRequest,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateTemplateResponse> {
      const localVarAxiosArgs = TemplatesApiAxiosParamCreator(configuration).createTransferTemplate(request, options)
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     * Данный запрос служит для удаления шаблона. Применим для всех типов шаблонов.
     * @summary Запрос на удаление шаблона любого типа
     * @param {DeleteTemplateRequest} request Тело запроса
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteTemplate(
      request: DeleteTemplateRequest,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ErrorInfo> {
      const localVarAxiosArgs = TemplatesApiAxiosParamCreator(configuration).deleteTemplate(request, options)
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     * Запрос используется для пакетного удаления шаблонов по идентификаторам. Применим для всех типов шаблонов.
     * @summary Запрос для пакетного удаления шаблонов
     * @param {DeleteTemplatesBatchRequest} request Тело запроса
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteTemplatesBatch(
      request: DeleteTemplatesBatchRequest,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ErrorInfo> {
      const localVarAxiosArgs = TemplatesApiAxiosParamCreator(configuration).deleteTemplatesBatch(request, options)
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     * Данный запрос может использователься в следующих ситуациях: получение всех доступных пользователю шаблонов, получение всех доступных шаблонов платежей выбранной категории, получение всех доступных шаблонов платежей для выбранного сервиса, получение всех доступных пользователю шаблонов для определенного платежного инструмента, получение данных конкретного шаблона. Параметр bindingId может использоваться вместе с categoryName и serviceId в качестве дополнительного фильтра. Использование параметра serviceId делает ненужным использование параметра categoryName. Использование параметра templateId делает ненужным использование других фильтров.
     * @summary Запрос на получение списка шаблонов платежей пользователя
     * @param {GetTemplatesRequest} request Тело запроса
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTemplates(
      request: GetTemplatesRequest,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetTemplatesResponse> {
      const localVarAxiosArgs = TemplatesApiAxiosParamCreator(configuration).getTemplates(request, options)
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     * Данный запрос может использователься в следующих ситуациях: получение всех доступных пользователю шаблонов, получение всех доступных пользователю шаблонов для определенного платежного инструмента (рассматривается только инструмент-источник перевода), получение конкретного шаблона (в этом случае указывается templateId, bindingId опускается).
     * @summary Запрос на получение списка шаблонов переводов пользователя
     * @param {GetTransferTemplatesRequest} request Тело запроса
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTransferTemplates(
      request: GetTransferTemplatesRequest,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetTransferTemplatesResponse> {
      const localVarAxiosArgs = TemplatesApiAxiosParamCreator(configuration).getTransferTemplates(request, options)
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     * Запрос используется для перепривязки всех шаблонов к новому платежному инструменту, используется в слоучае удаления или истечения срока действия платежного инструмента. Применим для всех типов шаблонов
     * @summary Запрос для перепривязки всех шаблонов
     * @param {ReplaceTemplatesBindingRequest} request Тело запроса
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    replaceTemplatesBinding(
      request: ReplaceTemplatesBindingRequest,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ErrorInfo> {
      const localVarAxiosArgs = TemplatesApiAxiosParamCreator(configuration).replaceTemplatesBinding(request, options)
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     * Данный запрос служит для переименования и изменения параметров шаблона. Все параметры шаблона передаются целиком даже в том случае, если изменение точечное, если какой-то из параметров изменять не нужно, то передается старое значение.
     * @summary Запрос на изменение шаблона платежа
     * @param {UpdateTemplateRequest} request Тело запроса
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateTemplate(
      request: UpdateTemplateRequest,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdateTemplateResponse> {
      const localVarAxiosArgs = TemplatesApiAxiosParamCreator(configuration).updateTemplate(request, options)
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     * Данный запрос служит для переименования и изменения параметров шаблона. Все параметры шаблона передаются всегда целиком, если какой-то из параметров изменять не нужно, то передается старое значение.
     * @summary Запрос на изменение шаблона перевода
     * @param {UpdateTransferTemplateRequest} request Тело запроса
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateTransferTemplate(
      request: UpdateTransferTemplateRequest,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ErrorInfo> {
      const localVarAxiosArgs = TemplatesApiAxiosParamCreator(configuration).updateTransferTemplate(request, options)
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
  }
}

/**
 * TemplatesApi - factory interface
 * @export
 */
export const TemplatesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  return {
    /**
     * Данный запрос служит для создания шаблона для последующего проведения операции без заполнения всех параметров платежа пользователем. Создание шаблона возможно двумя способами: - Создание шаблона на основании успешно проведенной операции. При этом в запросе createTemplate передает-ся mdOrder, анализируя который система формирует шаблон платежа, обогащенный атрибутами поставщика - Создание шаблона посредством прямого ввода параметров совершения платежа. При этом в запросе create-Template передаются данные по поставщику и платежный инструмент.
     * @summary Запрос создания шаблона плетежа
     * @param {CreateTemplateRequest} request Тело запроса
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createTemplate(request: CreateTemplateRequest, options?: any): AxiosPromise<CreateTemplateResponse> {
      return TemplatesApiFp(configuration).createTemplate(request, options)(axios, basePath)
    },
    /**
     * Данный запрос служит для создания шаблона перевода для последующего проведения операции без заполнения всех параметров перевода пользователем. Шаблон перевода может быть создан на основании проведенного перевода, в этом случае все параметры перевода могут браться с проведенной ранее операции перевода, в этом случае в запросе указывается идентификатор ранее проведенной операции перевода.
     * @summary Запрос на создание шаблона перевода
     * @param {CreateTransferTemplateRequest} request Тело запроса
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createTransferTemplate(
      request: CreateTransferTemplateRequest,
      options?: any
    ): AxiosPromise<CreateTemplateResponse> {
      return TemplatesApiFp(configuration).createTransferTemplate(request, options)(axios, basePath)
    },
    /**
     * Данный запрос служит для удаления шаблона. Применим для всех типов шаблонов.
     * @summary Запрос на удаление шаблона любого типа
     * @param {DeleteTemplateRequest} request Тело запроса
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteTemplate(request: DeleteTemplateRequest, options?: any): AxiosPromise<ErrorInfo> {
      return TemplatesApiFp(configuration).deleteTemplate(request, options)(axios, basePath)
    },
    /**
     * Запрос используется для пакетного удаления шаблонов по идентификаторам. Применим для всех типов шаблонов.
     * @summary Запрос для пакетного удаления шаблонов
     * @param {DeleteTemplatesBatchRequest} request Тело запроса
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteTemplatesBatch(request: DeleteTemplatesBatchRequest, options?: any): AxiosPromise<ErrorInfo> {
      return TemplatesApiFp(configuration).deleteTemplatesBatch(request, options)(axios, basePath)
    },
    /**
     * Данный запрос может использователься в следующих ситуациях: получение всех доступных пользователю шаблонов, получение всех доступных шаблонов платежей выбранной категории, получение всех доступных шаблонов платежей для выбранного сервиса, получение всех доступных пользователю шаблонов для определенного платежного инструмента, получение данных конкретного шаблона. Параметр bindingId может использоваться вместе с categoryName и serviceId в качестве дополнительного фильтра. Использование параметра serviceId делает ненужным использование параметра categoryName. Использование параметра templateId делает ненужным использование других фильтров.
     * @summary Запрос на получение списка шаблонов платежей пользователя
     * @param {GetTemplatesRequest} request Тело запроса
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTemplates(request: GetTemplatesRequest, options?: any): AxiosPromise<GetTemplatesResponse> {
      return TemplatesApiFp(configuration).getTemplates(request, options)(axios, basePath)
    },
    /**
     * Данный запрос может использователься в следующих ситуациях: получение всех доступных пользователю шаблонов, получение всех доступных пользователю шаблонов для определенного платежного инструмента (рассматривается только инструмент-источник перевода), получение конкретного шаблона (в этом случае указывается templateId, bindingId опускается).
     * @summary Запрос на получение списка шаблонов переводов пользователя
     * @param {GetTransferTemplatesRequest} request Тело запроса
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTransferTemplates(
      request: GetTransferTemplatesRequest,
      options?: any
    ): AxiosPromise<GetTransferTemplatesResponse> {
      return TemplatesApiFp(configuration).getTransferTemplates(request, options)(axios, basePath)
    },
    /**
     * Запрос используется для перепривязки всех шаблонов к новому платежному инструменту, используется в слоучае удаления или истечения срока действия платежного инструмента. Применим для всех типов шаблонов
     * @summary Запрос для перепривязки всех шаблонов
     * @param {ReplaceTemplatesBindingRequest} request Тело запроса
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    replaceTemplatesBinding(request: ReplaceTemplatesBindingRequest, options?: any): AxiosPromise<ErrorInfo> {
      return TemplatesApiFp(configuration).replaceTemplatesBinding(request, options)(axios, basePath)
    },
    /**
     * Данный запрос служит для переименования и изменения параметров шаблона. Все параметры шаблона передаются целиком даже в том случае, если изменение точечное, если какой-то из параметров изменять не нужно, то передается старое значение.
     * @summary Запрос на изменение шаблона платежа
     * @param {UpdateTemplateRequest} request Тело запроса
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateTemplate(request: UpdateTemplateRequest, options?: any): AxiosPromise<UpdateTemplateResponse> {
      return TemplatesApiFp(configuration).updateTemplate(request, options)(axios, basePath)
    },
    /**
     * Данный запрос служит для переименования и изменения параметров шаблона. Все параметры шаблона передаются всегда целиком, если какой-то из параметров изменять не нужно, то передается старое значение.
     * @summary Запрос на изменение шаблона перевода
     * @param {UpdateTransferTemplateRequest} request Тело запроса
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateTransferTemplate(request: UpdateTransferTemplateRequest, options?: any): AxiosPromise<ErrorInfo> {
      return TemplatesApiFp(configuration).updateTransferTemplate(request, options)(axios, basePath)
    },
  }
}

/**
 * TemplatesApi - object-oriented interface
 * @export
 * @class TemplatesApi
 * @extends {BaseAPI}
 */
export class TemplatesApi extends BaseAPI {
  /**
   * Данный запрос служит для создания шаблона для последующего проведения операции без заполнения всех параметров платежа пользователем. Создание шаблона возможно двумя способами: - Создание шаблона на основании успешно проведенной операции. При этом в запросе createTemplate передает-ся mdOrder, анализируя который система формирует шаблон платежа, обогащенный атрибутами поставщика - Создание шаблона посредством прямого ввода параметров совершения платежа. При этом в запросе create-Template передаются данные по поставщику и платежный инструмент.
   * @summary Запрос создания шаблона плетежа
   * @param {CreateTemplateRequest} request Тело запроса
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TemplatesApi
   */
  public createTemplate(request: CreateTemplateRequest, options?: any) {
    return TemplatesApiFp(this.configuration).createTemplate(request, options)(this.axios, this.basePath)
  }

  /**
   * Данный запрос служит для создания шаблона перевода для последующего проведения операции без заполнения всех параметров перевода пользователем. Шаблон перевода может быть создан на основании проведенного перевода, в этом случае все параметры перевода могут браться с проведенной ранее операции перевода, в этом случае в запросе указывается идентификатор ранее проведенной операции перевода.
   * @summary Запрос на создание шаблона перевода
   * @param {CreateTransferTemplateRequest} request Тело запроса
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TemplatesApi
   */
  public createTransferTemplate(request: CreateTransferTemplateRequest, options?: any) {
    return TemplatesApiFp(this.configuration).createTransferTemplate(request, options)(this.axios, this.basePath)
  }

  /**
   * Данный запрос служит для удаления шаблона. Применим для всех типов шаблонов.
   * @summary Запрос на удаление шаблона любого типа
   * @param {DeleteTemplateRequest} request Тело запроса
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TemplatesApi
   */
  public deleteTemplate(request: DeleteTemplateRequest, options?: any) {
    return TemplatesApiFp(this.configuration).deleteTemplate(request, options)(this.axios, this.basePath)
  }

  /**
   * Запрос используется для пакетного удаления шаблонов по идентификаторам. Применим для всех типов шаблонов.
   * @summary Запрос для пакетного удаления шаблонов
   * @param {DeleteTemplatesBatchRequest} request Тело запроса
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TemplatesApi
   */
  public deleteTemplatesBatch(request: DeleteTemplatesBatchRequest, options?: any) {
    return TemplatesApiFp(this.configuration).deleteTemplatesBatch(request, options)(this.axios, this.basePath)
  }

  /**
   * Данный запрос может использователься в следующих ситуациях: получение всех доступных пользователю шаблонов, получение всех доступных шаблонов платежей выбранной категории, получение всех доступных шаблонов платежей для выбранного сервиса, получение всех доступных пользователю шаблонов для определенного платежного инструмента, получение данных конкретного шаблона. Параметр bindingId может использоваться вместе с categoryName и serviceId в качестве дополнительного фильтра. Использование параметра serviceId делает ненужным использование параметра categoryName. Использование параметра templateId делает ненужным использование других фильтров.
   * @summary Запрос на получение списка шаблонов платежей пользователя
   * @param {GetTemplatesRequest} request Тело запроса
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TemplatesApi
   */
  public getTemplates(request: GetTemplatesRequest, options?: any) {
    return TemplatesApiFp(this.configuration).getTemplates(request, options)(this.axios, this.basePath)
  }

  /**
   * Данный запрос может использователься в следующих ситуациях: получение всех доступных пользователю шаблонов, получение всех доступных пользователю шаблонов для определенного платежного инструмента (рассматривается только инструмент-источник перевода), получение конкретного шаблона (в этом случае указывается templateId, bindingId опускается).
   * @summary Запрос на получение списка шаблонов переводов пользователя
   * @param {GetTransferTemplatesRequest} request Тело запроса
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TemplatesApi
   */
  public getTransferTemplates(request: GetTransferTemplatesRequest, options?: any) {
    return TemplatesApiFp(this.configuration).getTransferTemplates(request, options)(this.axios, this.basePath)
  }

  /**
   * Запрос используется для перепривязки всех шаблонов к новому платежному инструменту, используется в слоучае удаления или истечения срока действия платежного инструмента. Применим для всех типов шаблонов
   * @summary Запрос для перепривязки всех шаблонов
   * @param {ReplaceTemplatesBindingRequest} request Тело запроса
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TemplatesApi
   */
  public replaceTemplatesBinding(request: ReplaceTemplatesBindingRequest, options?: any) {
    return TemplatesApiFp(this.configuration).replaceTemplatesBinding(request, options)(this.axios, this.basePath)
  }

  /**
   * Данный запрос служит для переименования и изменения параметров шаблона. Все параметры шаблона передаются целиком даже в том случае, если изменение точечное, если какой-то из параметров изменять не нужно, то передается старое значение.
   * @summary Запрос на изменение шаблона платежа
   * @param {UpdateTemplateRequest} request Тело запроса
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TemplatesApi
   */
  public updateTemplate(request: UpdateTemplateRequest, options?: any) {
    return TemplatesApiFp(this.configuration).updateTemplate(request, options)(this.axios, this.basePath)
  }

  /**
   * Данный запрос служит для переименования и изменения параметров шаблона. Все параметры шаблона передаются всегда целиком, если какой-то из параметров изменять не нужно, то передается старое значение.
   * @summary Запрос на изменение шаблона перевода
   * @param {UpdateTransferTemplateRequest} request Тело запроса
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TemplatesApi
   */
  public updateTransferTemplate(request: UpdateTransferTemplateRequest, options?: any) {
    return TemplatesApiFp(this.configuration).updateTransferTemplate(request, options)(this.axios, this.basePath)
  }
}
