import React, { ChangeEvent, FC, useCallback, useState } from 'react';
import { AutoComplete, Icon, Text } from '@mtsbank/ui-kit';
import { Payee } from '@open-api/ump/catalog-manager';
import { useGetPayees } from '@components/Categories/swr';
import { searchCardClickGtm } from '@root/utils/gtm/tsp/events';
import { SPA } from '@root/constants';
import { IconTsp, Link, SearchInputWrapper, TspWrapper } from './styled';
export const CategoryAutoComplete: FC = () => {
  const [suggestions, setSuggestions] = useState<Payee[]>([]);
  const [selectedValue, setSelectedValue] = useState({
    value: ''
  });
  const {
    payees
  } = useGetPayees();
  const fetchSuggestions = (query: string) => new Promise<void>(resolve => {
    const filteredPayees = payees?.filter(payee => payee.providerName?.toLowerCase().includes(query.toLowerCase()));
    resolve(setSuggestions(filteredPayees));
  });
  const getSuggestionValue = (payee: Payee) => payee.providerName;
  const handleValueClear = () => {
    setSelectedValue({
      value: ''
    });
  };
  const handleChangeValue = ({
    currentTarget: {
      value
    }
  }: ChangeEvent<HTMLInputElement>) => {
    setSelectedValue({
      ...selectedValue,
      value
    });
  };
  const inputProps = {
    placeholder: 'Например, ЖКХ Москвы',
    value: selectedValue.value,
    isClearable: true,
    onClear: handleValueClear,
    onChange: handleChangeValue,
    controlChildren: <Icon icon="baseX24/ic-search" />
  };
  const getRouteTSPPath = (serviceId: string) => `${SPA}/pay/${serviceId}`;
  const renderSuggestion = useCallback((payee: Payee) => {
    const {
      icon,
      providerName,
      serviceId
    } = payee;
    const tspIcon = icon ? `${process.env.NEXT_PUBLIC_STATIC_HOST}/prov-icons/${icon}` : null;
    return <Link href={getRouteTSPPath(serviceId)}>
        <TspWrapper>
          {tspIcon && <IconTsp src={tspIcon} />}
          <Text>{providerName}</Text>
        </TspWrapper>
      </Link>;
  }, []);
  const onSuggestionSelected = useCallback(({
    providerName
  }: Payee) => {
    searchCardClickGtm(providerName);
    setSelectedValue({
      ...selectedValue,
      value: providerName
    });
  }, [selectedValue]);
  return <SearchInputWrapper>
      <AutoComplete<Payee> debounce debounceDelay={900} inputProps={inputProps} suggestions={suggestions} fetchSuggestions={fetchSuggestions} renderSuggestion={renderSuggestion} getSuggestionValue={getSuggestionValue} onSuggestionSelected={onSuggestionSelected} />
    </SearchInputWrapper>;
};