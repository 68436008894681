import React, { FC } from 'react';
import { SkeletonCir, SkeletonRect } from '@mtsbank/ui-kit';
import { ItemWrapper, ListItemsWrapper, ItemIconWrapper } from './styled';
export const Loading: FC = () => <ListItemsWrapper>
    {new Array(8).fill(null).map((_, i) => <ItemWrapper key={i}>
        <ItemIconWrapper>
          <SkeletonCir height={44} />
        </ItemIconWrapper>
        <SkeletonRect height={24} />
      </ItemWrapper>)}
  </ListItemsWrapper>;