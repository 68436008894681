import {
  events,
  eventCategories,
  sendGmtEvent,
  eventActions,
  eventLabels,
  screenNames,
  actionGroups,
  eventContents,
  eventContexts,
} from '@utils/gtm/baseFunctions'

export const formaParametrovPerevodaAuthScreenShow = () => {
  sendGmtEvent({
    event: events.mtsPageview,
    userAuth: '1',
    eventCategory: eventCategories.perevodyZaRubezh,
    eventAction: eventActions.screenShow,
    eventLabel: eventLabels.formaParametrovPerevoda,
    screenName: screenNames.transferAbroad,
    actionGroup: actionGroups.nonInteractions,
  })
}

export const formaParametrovPerevodaNotAuthScreenShow = () => {
  sendGmtEvent({
    event: events.mtsPageview,
    userAuth: '0',
    eventCategory: eventCategories.perevodyZaRubezh,
    eventAction: eventActions.screenShow,
    eventLabel: eventLabels.formaParametrovPerevoda,
    screenName: screenNames.transferAbroad,
    actionGroup: actionGroups.nonInteractions,
  })
}

export const formaParametrovPerevodaPerevodyAuthScreenShow = () => {
  sendGmtEvent({
    event: events.mtsPerevody,
    userAuth: '1',
    eventCategory: eventCategories.perevodyZaRubezh,
    eventAction: eventActions.screenShow,
    eventLabel: eventLabels.formaParametrovPerevoda,
    screenName: screenNames.transferAbroad,
    actionGroup: actionGroups.nonInteractions,
  })
}

export const formaParametrovPerevodaPerevodyNotAuthScreenShow = () => {
  sendGmtEvent({
    event: events.mtsPerevody,
    userAuth: '0',
    eventCategory: eventCategories.perevodyZaRubezh,
    eventAction: eventActions.screenShow,
    eventLabel: eventLabels.formaParametrovPerevoda,
    screenName: screenNames.transferAbroad,
    actionGroup: actionGroups.nonInteractions,
  })
}

export const formaDannyOtpravitelyaScreenShow = () => {
  sendGmtEvent({
    event: events.mtsPageview,
    userAuth: '1',
    eventCategory: eventCategories.perevodyZaRubezh,
    eventAction: eventActions.screenShow,
    eventLabel: eventLabels.formaDannyhOtpravitelya,
    screenName: screenNames.transferAbroad,
    actionGroup: actionGroups.nonInteractions,
  })
}

export const formaProverkiDannyhScreenShow = () => {
  sendGmtEvent({
    event: events.mtsPageview,
    userAuth: '1',
    eventCategory: eventCategories.perevodyZaRubezh,
    eventAction: eventActions.screenShow,
    eventLabel: eventLabels.formaProverkiDannyhOtpravitelya,
    screenName: screenNames.transferAbroad,
    actionGroup: actionGroups.nonInteractions,
  })
}

export const formaStatusaPerevodaScreenShow = (isRNKO: boolean) => {
  sendGmtEvent({
    event: events.mtsPageview,
    userAuth: '1',
    eventCategory: eventCategories.perevodyZaRubezh,
    eventAction: eventActions.screenShow,
    eventLabel: eventLabels.formaStatusaPerevoda,
    screenName: screenNames.transferAbroad,
    actionGroup: actionGroups.nonInteractions,
    CD2: { isRNKO },
  })
}

export const zaRubezhButtonClick = () => {
  sendGmtEvent({
    event: events.mtsPerevody,
    eventCategory: eventCategories.perevodyZaRubezh,
    eventAction: eventActions.buttonClick,
    eventLabel: eventLabels.zaRubezh,
    screenName: screenNames.transferCategories,
    actionGroup: actionGroups.interactions,
  })
}

export const authNextButtonClick = () => {
  sendGmtEvent({
    event: events.mtsPerevody,
    userAuth: '0',
    eventCategory: eventCategories.perevodyZaRubezh,
    eventAction: eventActions.buttonClick,
    eventLabel: eventLabels.authNext,
    screenName: screenNames.transferAbroad,
    actionGroup: actionGroups.interactions,
  })
}

export const userAndCountriesLoadingError = (code: string, text: string, method: string) => {
  sendGmtEvent({
    event: events.mtsPerevody,
    eventCategory: eventCategories.perevodyZaRubezh,
    eventAction: eventActions.errorShow,
    eventLabel: eventLabels.userAndCountriesLoadingErr,
    eventContext: eventContexts.vhodVScenarii,
    screenName: screenNames.transferAbroad,
    actionGroup: actionGroups.nonInteractions,
    CD1: code,
    CD2: text,
    CD3: method,
  })
}

// paymentInstrument указываем только если использована не новая карта
export const platezhnyInstrumentButtonClick = (paymentInstrument?: string) => {
  sendGmtEvent({
    event: events.mtsPerevody,
    eventCategory: eventCategories.perevodyZaRubezh,
    eventAction: eventActions.elementClick,
    eventLabel: eventLabels.paymentInstrument,
    screenName: screenNames.transferAbroad,
    actionGroup: actionGroups.interactions,
    CD4: paymentInstrument,
  })
}

export const newCardButtonClick = () => {
  sendGmtEvent({
    event: events.mtsPerevody,
    eventCategory: eventCategories.perevodyZaRubezh,
    eventAction: eventActions.elementClick,
    userAuth: '1',
    eventLabel: eventLabels.newCard,
    screenName: screenNames.transferAbroad,
    actionGroup: actionGroups.interactions,
  })
}

export const countryButtonClick = (country: string, paymentInstrumentType?: string) => {
  sendGmtEvent({
    event: events.mtsPerevody,
    eventCategory: eventCategories.perevodyZaRubezh,
    eventAction: eventActions.elementClick,
    userAuth: '1',
    eventLabel: eventLabels.country,
    screenName: screenNames.transferAbroad,
    actionGroup: actionGroups.interactions,
    CD4: paymentInstrumentType,
    CD5: country,
  })
}

export const currencyButtonClick = (currency: string, country: string, paymentInstrumentType?: string) => {
  sendGmtEvent({
    event: events.mtsPerevody,
    eventCategory: eventCategories.perevodyZaRubezh,
    eventAction: eventActions.elementClick,
    userAuth: '1',
    eventLabel: eventLabels.currency,
    screenName: screenNames.transferAbroad,
    actionGroup: actionGroups.interactions,
    CD4: paymentInstrumentType,
    CD5: country,
    CD6: currency,
  })
}

export const sumFieldBlur = (sum: string, currency: string, country: string, paymentInstrumentType?: string) => {
  sendGmtEvent({
    event: events.mtsPerevody,
    eventCategory: eventCategories.perevodyZaRubezh,
    eventAction: eventActions.elementClick,
    userAuth: '1',
    eventLabel: eventLabels.sum,
    eventValue: sum,
    screenName: screenNames.transferAbroad,
    actionGroup: actionGroups.interactions,
    CD4: paymentInstrumentType,
    CD5: country,
    CD6: currency,
  })
}

export const commisionErrorShow = (code: string, text: string, method: string) => {
  sendGmtEvent({
    event: events.mtsPerevody,
    eventCategory: eventCategories.perevodyZaRubezh,
    eventAction: eventActions.errorShow,
    userAuth: '1',
    eventLabel: eventLabels.commissionError,
    screenName: screenNames.transferAbroad,
    actionGroup: actionGroups.nonInteractions,
    CD1: code,
    CD2: text,
    CD3: method,
  })
}

export const transferMethodButtonClick = (
  sum: string,
  country: string,
  currency: string,
  paymentMethod: string,
  paymentInstrumentType?: string
) => {
  sendGmtEvent({
    event: events.mtsPerevody,
    eventCategory: eventCategories.perevodyZaRubezh,
    eventAction: eventActions.elementClick,
    userAuth: '1',
    eventLabel: eventLabels.paymentMethode,
    eventValue: sum,
    screenName: screenNames.transferAbroad,
    actionGroup: actionGroups.interactions,
    CD4: paymentInstrumentType,
    CD5: country,
    CD6: currency,
    CD7: paymentMethod,
  })
}

export const additionalFieldsLoadingError = (code: string, text: string, method: string) => {
  sendGmtEvent({
    event: events.mtsPerevody,
    eventCategory: eventCategories.perevodyZaRubezh,
    eventAction: eventActions.errorShow,
    userAuth: '1',
    eventLabel: eventLabels.additionalFieldsError,
    screenName: screenNames.transferAbroad,
    actionGroup: actionGroups.nonInteractions,
    CD1: code,
    CD2: text,
    CD3: method,
  })
}

export const additionalFieldsClick = () => {
  sendGmtEvent({
    event: events.mtsPerevody,
    eventCategory: eventCategories.perevodyZaRubezh,
    eventAction: eventActions.formClick,
    userAuth: '1',
    eventLabel: eventLabels.additionalFields,
    screenName: screenNames.transferAbroad,
    actionGroup: actionGroups.interactions,
  })
}

export const backBtnClickTransferParams = () => {
  sendGmtEvent({
    event: events.mtsPerevody,
    eventCategory: eventCategories.perevodyZaRubezh,
    eventAction: eventActions.buttonClick,
    userAuth: '1',
    eventLabel: eventLabels.back,
    screenName: screenNames.transferAbroad,
    actionGroup: actionGroups.interactions,
    eventContent: eventContents.formParams,
  })
}

export const ContinueParamsButtonClick = (
  sum: string,
  country: string,
  currency: string,
  paymentMethod: string,
  paymentInstrumentType?: string
) => {
  sendGmtEvent({
    event: events.mtsPerevody,
    eventCategory: eventCategories.perevodyZaRubezh,
    eventAction: eventActions.buttonClick,
    userAuth: '1',
    eventLabel: eventLabels.continue,
    screenName: screenNames.transferAbroad,
    actionGroup: actionGroups.interactions,
    eventContent: eventContents.formParams,
    eventValue: sum,
    CD4: paymentInstrumentType,
    CD5: country,
    CD6: currency,
    CD7: paymentMethod,
  })
}

export const dossierLoadingError = (code: string, text: string, method: string) => {
  sendGmtEvent({
    event: events.mtsPerevody,
    eventCategory: eventCategories.perevodyZaRubezh,
    eventAction: eventActions.errorShow,
    userAuth: '1',
    eventLabel: eventLabels.dossierSearchError,
    screenName: screenNames.transferAbroad,
    actionGroup: actionGroups.nonInteractions,
    CD1: code,
    CD2: text,
    CD3: method,
  })
}

export const senderFormShow = () => {
  sendGmtEvent({
    event: events.mtsPerevody,
    eventCategory: eventCategories.perevodyZaRubezh,
    eventAction: eventActions.screenShow,
    userAuth: '1',
    eventLabel: eventLabels.senderForm,
    screenName: screenNames.transferAbroad,
    actionGroup: actionGroups.nonInteractions,
  })
}

export const senderFormBackClick = () => {
  sendGmtEvent({
    event: events.mtsPerevody,
    eventCategory: eventCategories.perevodyZaRubezh,
    eventAction: eventActions.buttonClick,
    userAuth: '1',
    eventLabel: eventLabels.nazad,
    screenName: screenNames.transferAbroad,
    actionGroup: actionGroups.interactions,
    eventContent: eventContents.senderForm,
  })
}

export const continueSenderForm = () => {
  sendGmtEvent({
    event: events.mtsPerevody,
    eventCategory: eventCategories.perevodyZaRubezh,
    eventAction: eventActions.buttonClick,
    userAuth: '1',
    eventLabel: eventLabels.prodolzhit,
    screenName: screenNames.transferAbroad,
    actionGroup: actionGroups.interactions,
    eventContent: eventContents.senderForm,
  })
}

export const errorDossierCreating = (code: string, text: string, method: string) => {
  sendGmtEvent({
    event: events.mtsPerevody,
    eventCategory: eventCategories.perevodyZaRubezh,
    eventAction: eventActions.errorShow,
    userAuth: '1',
    eventLabel: eventLabels.dossierCreatingError,
    screenName: screenNames.transferAbroad,
    actionGroup: actionGroups.nonInteractions,
    CD1: code,
    CD2: text,
    CD3: method,
  })
}

export const dataCheckFormScreenShow = () => {
  sendGmtEvent({
    event: events.mtsPerevody,
    eventCategory: eventCategories.perevodyZaRubezh,
    eventAction: eventActions.screenShow,
    userAuth: '1',
    eventLabel: eventLabels.formaProverkiDannyhOtpravitelya,
    screenName: screenNames.transferAbroad,
    actionGroup: actionGroups.nonInteractions,
  })
}

export const dataCheckFormBackClick = () => {
  sendGmtEvent({
    event: events.mtsPerevody,
    eventCategory: eventCategories.perevodyZaRubezh,
    eventAction: eventActions.buttonClick,
    userAuth: '1',
    eventLabel: eventLabels.nazad,
    screenName: screenNames.transferAbroad,
    actionGroup: actionGroups.interactions,
    eventContent: eventContents.checkForm,
  })
}

export const transferMoneyButtonClick = (
  sum: string,
  country: string,
  currency: string,
  paymentMethod: string,
  paymentInstrumentType?: string
) => {
  sendGmtEvent({
    event: events.mtsPerevody,
    eventCategory: eventCategories.perevodyZaRubezh,
    eventAction: eventActions.buttonClick,
    userAuth: '1',
    eventLabel: eventLabels.perevesti,
    eventValue: sum,
    screenName: screenNames.transferAbroad,
    actionGroup: actionGroups.interactions,
    CD4: paymentInstrumentType,
    CD5: country,
    CD6: currency,
    CD7: paymentMethod,
  })
}

export const errorCommissions = () => {
  sendGmtEvent({
    event: events.mtsPerevody,
    eventCategory: eventCategories.perevodyZaRubezh,
    eventAction: eventActions.errorShow,
    userAuth: '1',
    eventLabel: eventLabels.commissionError,
    screenName: screenNames.transferAbroad,
    actionGroup: actionGroups.nonInteractions,
  })
}

export const transferStateFormScreenShow = (state: string) => {
  sendGmtEvent({
    event: events.mtsPerevody,
    eventCategory: eventCategories.perevodyZaRubezh,
    eventAction: eventActions.screenShow,
    userAuth: '1',
    eventLabel: eventLabels.stateForm,
    screenName: screenNames.transferAbroad,
    actionGroup: actionGroups.nonInteractions,
    CD8: state,
  })
}

export const getReceiptButtonClick = () => {
  sendGmtEvent({
    event: events.mtsPerevody,
    eventCategory: eventCategories.perevodyZaRubezh,
    eventAction: eventActions.buttonClick,
    userAuth: '1',
    eventLabel: eventLabels.receipt,
    screenName: screenNames.transferAbroad,
    actionGroup: actionGroups.interactions,
  })
}

export const transferConditionsClick = () => {
  sendGmtEvent({
    event: events.mtsPerevody,
    eventCategory: eventCategories.perevodyZaRubezh,
    eventAction: eventActions.linkClick,
    userAuth: '1',
    eventLabel: eventLabels.transferConditions,
    screenName: screenNames.transferAbroad,
    actionGroup: actionGroups.interactions,
  })
}
