import processStamp from '@components/HistoryOperation/icons/processing.png'
import approveStamp from '@components/HistoryOperation/icons/approve.png'
import declineStamp from '@components/HistoryOperation/icons/decline.png'
import { Status } from '@root/utils/transfer/getStatusName'
import { NoticeType } from '@components/Notice/types'
import { authHelper } from '@utils/authHelper/AuthHelper'

export const stampsMapper = {
  [Status.FAIL]: declineStamp,
  [Status.WAIT]: processStamp,
  [Status.SUCCESS]: approveStamp,
}

export const stateMapper = {
  [Status.FAIL]: 'REJECTED',
  [Status.WAIT]: 'PROCESSING',
  [Status.SUCCESS]: 'PROCESSED',
}

export const matchingTransferByStatus = {
  IN_PROGRESS: NoticeType.WAIT,
  EXECUTED: NoticeType.SUCCESS,
  REJECTED: NoticeType.ERROR,
}

export const getPcekZone = (authZone?: boolean) => {
  const { isAuthenticated } = authHelper

  const isAuth = authZone || isAuthenticated()

  return isAuth ? 'b' : 'anonymous'
}
