import React, { FC, useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { Badge, SkeletonRect, theme } from '@mtsbank/ui-kit';
import { tabClickGtm } from '@root/utils/gtm/tsp/events';
import { Transfers } from './Transfers';
import { Payments } from './Payments';
import { BadgeStyles, Container, TabsWrapper } from './styled';
import { Loading } from './Loading';
export enum PaymentsAndTransfersTabs {
  PAYMENTS = 'payments',
  TRANSFERS = 'transfers',
}
export const PaymentsAndTransfers: FC = () => {
  const router = useRouter();
  const [activeTab, setActiveTab] = useState<PaymentsAndTransfersTabs>();
  const [isMounted, setIsMounted] = useState<boolean>(false);
  const handleChangeTab = (value: PaymentsAndTransfersTabs) => {
    tabClickGtm(value);
    router.push({
      query: `tab=${value}`
    }, undefined, {
      scroll: false
    });
  };
  useEffect(() => {
    if (!router.isReady) {
      return;
    }
    const tab = (router.query?.tab as PaymentsAndTransfersTabs);
    if (!tab && !isMounted) {
      router.push({
        query: `tab=${PaymentsAndTransfersTabs.PAYMENTS}`
      }, undefined, {
        scroll: false
      });
      setActiveTab(PaymentsAndTransfersTabs.PAYMENTS);
    }
    if (!isMounted) {
      setIsMounted(true);
    }
    if (tab) {
      setActiveTab(tab);
    }
  }, [router, isMounted]);
  if (!activeTab) {
    return <Container>
        <TabsWrapper style={{
        display: 'flex',
        alignItems: 'center',
        gap: 8,
        width: '228px'
      }}>
          <SkeletonRect height={32} radius={32} />
          <SkeletonRect height={32} radius={32} />
        </TabsWrapper>

        <Loading />
      </Container>;
  }
  return <Container>
      <TabsWrapper>
        {Object.values(PaymentsAndTransfersTabs).map((tab, i) => <Badge dynamic size="lg" colorType="custom" hoverColor={theme.colors.red.hover} value={tab === PaymentsAndTransfersTabs.PAYMENTS ? 'Платежи' : 'Переводы'} color={activeTab === tab ? '#18191C' : 'inherit'} type={activeTab === tab ? 'primary' : 'secondary'} onClick={() => handleChangeTab(tab)} className={BadgeStyles} key={i} />)}
      </TabsWrapper>

      {activeTab === PaymentsAndTransfersTabs.PAYMENTS && <Payments />}
      {activeTab === PaymentsAndTransfersTabs.TRANSFERS && <Transfers />}
    </Container>;
};