// tslint:disable
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as globalImportUrl from 'url'
import { Configuration } from './configuration'
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios'
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base'

/**
 *
 * @export
 * @interface Alias
 */
export interface Alias {
  /**
   *
   * @type {string}
   * @memberof Alias
   */
  invoiceProviderCode: string
  /**
   *
   * @type {string}
   * @memberof Alias
   */
  alias: string
}
/**
 *
 * @export
 * @interface CategoriesResponse
 */
export interface CategoriesResponse {
  /**
   *
   * @type {string}
   * @memberof CategoriesResponse
   */
  requestId: string
  /**
   *
   * @type {Array<Category>}
   * @memberof CategoriesResponse
   */
  categories: Array<Category>
}
/**
 *
 * @export
 * @interface Category
 */
export interface Category {
  /**
   *
   * @type {boolean}
   * @memberof Category
   */
  hidden: boolean
  /**
   *
   * @type {string}
   * @memberof Category
   */
  name: string
  /**
   *
   * @type {string}
   * @memberof Category
   */
  title: string
  /**
   *
   * @type {string}
   * @memberof Category
   */
  icon?: string
  /**
   *
   * @type {Array<Payee>}
   * @memberof Category
   */
  payees?: Array<Payee>
  /**
   *
   * @type {QrCode}
   * @memberof Category
   */
  qrCode?: QrCode
}
/**
 *
 * @export
 * @interface CurrentVersionResponse
 */
export interface CurrentVersionResponse {
  /**
   *
   * @type {string}
   * @memberof CurrentVersionResponse
   */
  requestId: string
  /**
   *
   * @type {string}
   * @memberof CurrentVersionResponse
   */
  version: string
  /**
   *
   * @type {string}
   * @memberof CurrentVersionResponse
   */
  iconsURL: string
}
/**
 *
 * @export
 * @interface EnumElement
 */
export interface EnumElement {
  /**
   *
   * @type {string}
   * @memberof EnumElement
   */
  id: string
  /**
   *
   * @type {string}
   * @memberof EnumElement
   */
  value: string
}
/**
 *
 * @export
 * @interface EnumsResponse
 */
export interface EnumsResponse {
  /**
   *
   * @type {string}
   * @memberof EnumsResponse
   */
  requestId: string
  /**
   *
   * @type {Array<ModelEnum>}
   * @memberof EnumsResponse
   */
  enums: Array<ModelEnum>
}
/**
 *
 * @export
 * @interface Limits
 */
export interface Limits {
  /**
   *
   * @type {number}
   * @memberof Limits
   */
  min?: number
  /**
   *
   * @type {number}
   * @memberof Limits
   */
  max?: number
}
/**
 *
 * @export
 * @interface ModelEnum
 */
export interface ModelEnum {
  /**
   *
   * @type {string}
   * @memberof ModelEnum
   */
  id: string
  /**
   *
   * @type {Array<EnumElement>}
   * @memberof ModelEnum
   */
  elements: Array<EnumElement>
}
/**
 *
 * @export
 * @interface Param
 */
export interface Param {
  /**
   *
   * @type {string}
   * @memberof Param
   */
  title: string
  /**
   *
   * @type {string}
   * @memberof Param
   */
  name: string
  /**
   *
   * @type {string}
   * @memberof Param
   */
  type: string
  /**
   *
   * @type {string}
   * @memberof Param
   */
  description?: string
  /**
   *
   * @type {boolean}
   * @memberof Param
   */
  hidden: boolean
  /**
   *
   * @type {string}
   * @memberof Param
   */
  mask?: string
  /**
   *
   * @type {string}
   * @memberof Param
   */
  regExp?: string
  /**
   *
   * @type {number}
   * @memberof Param
   */
  maxLength?: number
  /**
   *
   * @type {string}
   * @memberof Param
   */
  defaultValue?: string
  /**
   *
   * @type {boolean}
   * @memberof Param
   */
  serverValidation: boolean
  /**
   *
   * @type {string}
   * @memberof Param
   */
  enumId?: string
  /**
   *
   * @type {string}
   * @memberof Param
   */
  userHint?: string
  /**
   *
   * @type {boolean}
   * @memberof Param
   */
  readOnly: boolean
  /**
   *
   * @type {boolean}
   * @memberof Param
   */
  required: boolean
  /**
   *
   * @type {number}
   * @memberof Param
   */
  displayOrder: number
  /**
   *
   * @type {Array<Alias>}
   * @memberof Param
   */
  aliases?: Array<Alias>
  /**
   *
   * @type {{ [key: string]: object; }}
   * @memberof Param
   */
  features?: { [key: string]: object }
}
/**
 *
 * @export
 * @interface Payee
 */
export interface Payee {
  /**
   *
   * @type {string}
   * @memberof Payee
   */
  serviceId: string
  /**
   *
   * @type {string}
   * @memberof Payee
   */
  categoryName: string
  /**
   *
   * @type {string}
   * @memberof Payee
   */
  subline: string
  /**
   *
   * @type {string}
   * @memberof Payee
   */
  providerName: string
  /**
   *
   * @type {string}
   * @memberof Payee
   */
  icon?: string
  /**
   *
   * @type {string}
   * @memberof Payee
   */
  iconOriginal?: string
  /**
   *
   * @type {string}
   * @memberof Payee
   */
  inn?: string
  /**
   *
   * @type {string}
   * @memberof Payee
   */
  kpp?: string
  /**
   *
   * @type {boolean}
   * @memberof Payee
   */
  hidden: boolean
  /**
   *
   * @type {boolean}
   * @memberof Payee
   */
  searchInvoicesEnabled: boolean
  /**
   *
   * @type {boolean}
   * @memberof Payee
   */
  autopaymentDisabled: boolean
  /**
   *
   * @type {boolean}
   * @memberof Payee
   */
  payOnCreditEnabled: boolean
  /**
   *
   * @type {boolean}
   * @memberof Payee
   */
  payByQrEnabled: boolean
  /**
   *
   * @type {boolean}
   * @memberof Payee
   */
  balanceInquiryEnabled: boolean
  /**
   *
   * @type {number}
   * @memberof Payee
   */
  mtsCategoryId?: number
  /**
   *
   * @type {Limits}
   * @memberof Payee
   */
  limits?: Limits
  /**
   *
   * @type {Array<string>}
   * @memberof Payee
   */
  bindingTypes?: Array<PayeeBindingTypesEnum>
  /**
   *
   * @type {Array<string>}
   * @memberof Payee
   */
  regions?: Array<string>
  /**
   *
   * @type {Array<Param>}
   * @memberof Payee
   */
  params?: Array<Param>
  /**
   *
   * @type {Array<Param>}
   * @memberof Payee
   */
  invoiceSearchParams?: Array<Param>
  /**
   *
   * @type {Array<Param>}
   * @memberof Payee
   */
  receiptParams?: Array<Param>
  /**
   *
   * @type {{ [key: string]: object; }}
   * @memberof Payee
   */
  features?: { [key: string]: object }
}

/**
 * @export
 * @enum {string}
 */
export enum PayeeBindingTypesEnum {
  MTSACCOUNT = 'MTS_ACCOUNT',
  BOUNDCARD = 'BOUND_CARD',
  ANONYMOUSCARD = 'ANONYMOUS_CARD',
  EMONEYACCOUNT = 'EMONEY_ACCOUNT',
  GENERALCARD = 'GENERAL_CARD',
  EXTERNALLYTOKENIZEDCARD = 'EXTERNALLY_TOKENIZED_CARD',
  BANKACCOUNT = 'BANK_ACCOUNT',
  APPLEPAY = 'APPLE_PAY',
  GOOGLEPAY = 'GOOGLE_PAY',
  SAMSUNGPAY = 'SAMSUNG_PAY',
  MDESTOKEN = 'MDES_TOKEN',
  VTSTOKEN = 'VTS_TOKEN',
  VIRTUALCARDLS = 'VIRTUAL_CARD_LS',
}

/**
 *
 * @export
 * @interface PayeesResponse
 */
export interface PayeesResponse {
  /**
   *
   * @type {string}
   * @memberof PayeesResponse
   */
  requestId: string
  /**
   *
   * @type {Array<Payee>}
   * @memberof PayeesResponse
   */
  payees: Array<Payee>
}
/**
 *
 * @export
 * @interface PaymentSubscriptionPayeesResponse
 */
export interface PaymentSubscriptionPayeesResponse {
  /**
   *
   * @type {string}
   * @memberof PaymentSubscriptionPayeesResponse
   */
  requestId: string
  /**
   *
   * @type {string}
   * @memberof PaymentSubscriptionPayeesResponse
   */
  version: string
  /**
   *
   * @type {Array<ServiceIds>}
   * @memberof PaymentSubscriptionPayeesResponse
   */
  serviceIds: Array<ServiceIds>
}
/**
 *
 * @export
 * @interface QrCode
 */
export interface QrCode {
  /**
   *
   * @type {string}
   * @memberof QrCode
   */
  action?: string
  /**
   *
   * @type {string}
   * @memberof QrCode
   */
  icon?: string
  /**
   *
   * @type {number}
   * @memberof QrCode
   */
  order?: number
}
/**
 *
 * @export
 * @interface Region
 */
export interface Region {
  /**
   *
   * @type {string}
   * @memberof Region
   */
  id: string
  /**
   *
   * @type {string}
   * @memberof Region
   */
  parentId?: string
  /**
   *
   * @type {string}
   * @memberof Region
   */
  value: string
}
/**
 *
 * @export
 * @interface RegionsResponse
 */
export interface RegionsResponse {
  /**
   *
   * @type {string}
   * @memberof RegionsResponse
   */
  requestId: string
  /**
   *
   * @type {Array<Region>}
   * @memberof RegionsResponse
   */
  regions: Array<Region>
}
/**
 *
 * @export
 * @interface SecurityConfiguration
 */
export interface SecurityConfiguration {
  /**
   *
   * @type {string}
   * @memberof SecurityConfiguration
   */
  apiKey?: string
  /**
   *
   * @type {string}
   * @memberof SecurityConfiguration
   */
  apiKeyVehicle?: string
  /**
   *
   * @type {string}
   * @memberof SecurityConfiguration
   */
  apiKeyName?: string
  /**
   *
   * @type {string}
   * @memberof SecurityConfiguration
   */
  clientId?: string
  /**
   *
   * @type {string}
   * @memberof SecurityConfiguration
   */
  clientSecret?: string
  /**
   *
   * @type {string}
   * @memberof SecurityConfiguration
   */
  realm?: string
  /**
   *
   * @type {string}
   * @memberof SecurityConfiguration
   */
  appName?: string
  /**
   *
   * @type {string}
   * @memberof SecurityConfiguration
   */
  scopeSeparator?: string
  /**
   *
   * @type {{ [key: string]: object; }}
   * @memberof SecurityConfiguration
   */
  additionalQueryStringParams?: { [key: string]: object }
  /**
   *
   * @type {boolean}
   * @memberof SecurityConfiguration
   */
  useBasicAuthenticationWithAccessCodeGrant?: boolean
}
/**
 *
 * @export
 * @interface ServiceIds
 */
export interface ServiceIds {
  /**
   *
   * @type {Payee}
   * @memberof ServiceIds
   */
  paymentServiceId: Payee
  /**
   *
   * @type {Payee}
   * @memberof ServiceIds
   */
  featureSearchInvoiceId: Payee
}
/**
 *
 * @export
 * @interface SwaggerResource
 */
export interface SwaggerResource {
  /**
   *
   * @type {string}
   * @memberof SwaggerResource
   */
  name?: string
  /**
   *
   * @type {string}
   * @memberof SwaggerResource
   */
  url?: string
  /**
   *
   * @type {string}
   * @memberof SwaggerResource
   */
  swaggerVersion?: string
  /**
   *
   * @type {string}
   * @memberof SwaggerResource
   */
  location?: string
}
/**
 *
 * @export
 * @interface UiConfiguration
 */
export interface UiConfiguration {
  /**
   *
   * @type {boolean}
   * @memberof UiConfiguration
   */
  deepLinking?: boolean
  /**
   *
   * @type {boolean}
   * @memberof UiConfiguration
   */
  displayOperationId?: boolean
  /**
   *
   * @type {number}
   * @memberof UiConfiguration
   */
  defaultModelsExpandDepth?: number
  /**
   *
   * @type {number}
   * @memberof UiConfiguration
   */
  defaultModelExpandDepth?: number
  /**
   *
   * @type {string}
   * @memberof UiConfiguration
   */
  defaultModelRendering?: UiConfigurationDefaultModelRenderingEnum
  /**
   *
   * @type {boolean}
   * @memberof UiConfiguration
   */
  displayRequestDuration?: boolean
  /**
   *
   * @type {string}
   * @memberof UiConfiguration
   */
  docExpansion?: UiConfigurationDocExpansionEnum
  /**
   *
   * @type {object}
   * @memberof UiConfiguration
   */
  filter?: object
  /**
   *
   * @type {number}
   * @memberof UiConfiguration
   */
  maxDisplayedTags?: number
  /**
   *
   * @type {string}
   * @memberof UiConfiguration
   */
  operationsSorter?: UiConfigurationOperationsSorterEnum
  /**
   *
   * @type {boolean}
   * @memberof UiConfiguration
   */
  showExtensions?: boolean
  /**
   *
   * @type {string}
   * @memberof UiConfiguration
   */
  tagsSorter?: UiConfigurationTagsSorterEnum
  /**
   *
   * @type {string}
   * @memberof UiConfiguration
   */
  validatorUrl?: string
  /**
   *
   * @type {string}
   * @memberof UiConfiguration
   */
  apisSorter?: string
  /**
   *
   * @type {boolean}
   * @memberof UiConfiguration
   */
  jsonEditor?: boolean
  /**
   *
   * @type {boolean}
   * @memberof UiConfiguration
   */
  showRequestHeaders?: boolean
  /**
   *
   * @type {Array<string>}
   * @memberof UiConfiguration
   */
  supportedSubmitMethods?: Array<string>
}

/**
 * @export
 * @enum {string}
 */
export enum UiConfigurationDefaultModelRenderingEnum {
  Example = 'example',
  Model = 'model',
}
/**
 * @export
 * @enum {string}
 */
export enum UiConfigurationDocExpansionEnum {
  None = 'none',
  List = 'list',
  Full = 'full',
}
/**
 * @export
 * @enum {string}
 */
export enum UiConfigurationOperationsSorterEnum {
  Alpha = 'alpha',
  Method = 'method',
}
/**
 * @export
 * @enum {string}
 */
export enum UiConfigurationTagsSorterEnum {
  Alpha = 'alpha',
}

/**
 * ApiResourceControllerApi - axios parameter creator
 * @export
 */
export const ApiResourceControllerApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    securityConfiguration(options: any = {}): RequestArgs {
      const localVarPath = `/swagger-resources/configuration/security`
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    securityConfiguration1(options: any = {}): RequestArgs {
      const localVarPath = `/swagger-resources/configuration/security`
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'OPTIONS', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    securityConfiguration2(options: any = {}): RequestArgs {
      const localVarPath = `/swagger-resources/configuration/security`
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    securityConfiguration3(options: any = {}): RequestArgs {
      const localVarPath = `/swagger-resources/configuration/security`
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    securityConfiguration4(options: any = {}): RequestArgs {
      const localVarPath = `/swagger-resources/configuration/security`
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    securityConfiguration5(options: any = {}): RequestArgs {
      const localVarPath = `/swagger-resources/configuration/security`
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    securityConfiguration6(options: any = {}): RequestArgs {
      const localVarPath = `/swagger-resources/configuration/security`
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'HEAD', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    swaggerResources(options: any = {}): RequestArgs {
      const localVarPath = `/swagger-resources`
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    swaggerResources1(options: any = {}): RequestArgs {
      const localVarPath = `/swagger-resources`
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'OPTIONS', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    swaggerResources2(options: any = {}): RequestArgs {
      const localVarPath = `/swagger-resources`
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    swaggerResources3(options: any = {}): RequestArgs {
      const localVarPath = `/swagger-resources`
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    swaggerResources4(options: any = {}): RequestArgs {
      const localVarPath = `/swagger-resources`
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    swaggerResources5(options: any = {}): RequestArgs {
      const localVarPath = `/swagger-resources`
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    swaggerResources6(options: any = {}): RequestArgs {
      const localVarPath = `/swagger-resources`
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'HEAD', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    uiConfiguration(options: any = {}): RequestArgs {
      const localVarPath = `/swagger-resources/configuration/ui`
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    uiConfiguration1(options: any = {}): RequestArgs {
      const localVarPath = `/swagger-resources/configuration/ui`
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'OPTIONS', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    uiConfiguration2(options: any = {}): RequestArgs {
      const localVarPath = `/swagger-resources/configuration/ui`
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    uiConfiguration3(options: any = {}): RequestArgs {
      const localVarPath = `/swagger-resources/configuration/ui`
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    uiConfiguration4(options: any = {}): RequestArgs {
      const localVarPath = `/swagger-resources/configuration/ui`
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    uiConfiguration5(options: any = {}): RequestArgs {
      const localVarPath = `/swagger-resources/configuration/ui`
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    uiConfiguration6(options: any = {}): RequestArgs {
      const localVarPath = `/swagger-resources/configuration/ui`
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'HEAD', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * ApiResourceControllerApi - functional programming interface
 * @export
 */
export const ApiResourceControllerApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    securityConfiguration(
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<SecurityConfiguration> {
      const localVarAxiosArgs = ApiResourceControllerApiAxiosParamCreator(configuration).securityConfiguration(options)
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    securityConfiguration1(
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<SecurityConfiguration> {
      const localVarAxiosArgs = ApiResourceControllerApiAxiosParamCreator(configuration).securityConfiguration1(options)
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    securityConfiguration2(
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<SecurityConfiguration> {
      const localVarAxiosArgs = ApiResourceControllerApiAxiosParamCreator(configuration).securityConfiguration2(options)
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    securityConfiguration3(
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<SecurityConfiguration> {
      const localVarAxiosArgs = ApiResourceControllerApiAxiosParamCreator(configuration).securityConfiguration3(options)
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    securityConfiguration4(
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<SecurityConfiguration> {
      const localVarAxiosArgs = ApiResourceControllerApiAxiosParamCreator(configuration).securityConfiguration4(options)
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    securityConfiguration5(
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<SecurityConfiguration> {
      const localVarAxiosArgs = ApiResourceControllerApiAxiosParamCreator(configuration).securityConfiguration5(options)
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    securityConfiguration6(
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<SecurityConfiguration> {
      const localVarAxiosArgs = ApiResourceControllerApiAxiosParamCreator(configuration).securityConfiguration6(options)
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    swaggerResources(
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SwaggerResource>> {
      const localVarAxiosArgs = ApiResourceControllerApiAxiosParamCreator(configuration).swaggerResources(options)
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    swaggerResources1(
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SwaggerResource>> {
      const localVarAxiosArgs = ApiResourceControllerApiAxiosParamCreator(configuration).swaggerResources1(options)
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    swaggerResources2(
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SwaggerResource>> {
      const localVarAxiosArgs = ApiResourceControllerApiAxiosParamCreator(configuration).swaggerResources2(options)
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    swaggerResources3(
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SwaggerResource>> {
      const localVarAxiosArgs = ApiResourceControllerApiAxiosParamCreator(configuration).swaggerResources3(options)
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    swaggerResources4(
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SwaggerResource>> {
      const localVarAxiosArgs = ApiResourceControllerApiAxiosParamCreator(configuration).swaggerResources4(options)
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    swaggerResources5(
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SwaggerResource>> {
      const localVarAxiosArgs = ApiResourceControllerApiAxiosParamCreator(configuration).swaggerResources5(options)
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    swaggerResources6(
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SwaggerResource>> {
      const localVarAxiosArgs = ApiResourceControllerApiAxiosParamCreator(configuration).swaggerResources6(options)
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    uiConfiguration(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<UiConfiguration> {
      const localVarAxiosArgs = ApiResourceControllerApiAxiosParamCreator(configuration).uiConfiguration(options)
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    uiConfiguration1(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<UiConfiguration> {
      const localVarAxiosArgs = ApiResourceControllerApiAxiosParamCreator(configuration).uiConfiguration1(options)
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    uiConfiguration2(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<UiConfiguration> {
      const localVarAxiosArgs = ApiResourceControllerApiAxiosParamCreator(configuration).uiConfiguration2(options)
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    uiConfiguration3(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<UiConfiguration> {
      const localVarAxiosArgs = ApiResourceControllerApiAxiosParamCreator(configuration).uiConfiguration3(options)
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    uiConfiguration4(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<UiConfiguration> {
      const localVarAxiosArgs = ApiResourceControllerApiAxiosParamCreator(configuration).uiConfiguration4(options)
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    uiConfiguration5(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<UiConfiguration> {
      const localVarAxiosArgs = ApiResourceControllerApiAxiosParamCreator(configuration).uiConfiguration5(options)
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    uiConfiguration6(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<UiConfiguration> {
      const localVarAxiosArgs = ApiResourceControllerApiAxiosParamCreator(configuration).uiConfiguration6(options)
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
  }
}

/**
 * ApiResourceControllerApi - factory interface
 * @export
 */
export const ApiResourceControllerApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    securityConfiguration(options?: any): AxiosPromise<SecurityConfiguration> {
      return ApiResourceControllerApiFp(configuration).securityConfiguration(options)(axios, basePath)
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    securityConfiguration1(options?: any): AxiosPromise<SecurityConfiguration> {
      return ApiResourceControllerApiFp(configuration).securityConfiguration1(options)(axios, basePath)
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    securityConfiguration2(options?: any): AxiosPromise<SecurityConfiguration> {
      return ApiResourceControllerApiFp(configuration).securityConfiguration2(options)(axios, basePath)
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    securityConfiguration3(options?: any): AxiosPromise<SecurityConfiguration> {
      return ApiResourceControllerApiFp(configuration).securityConfiguration3(options)(axios, basePath)
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    securityConfiguration4(options?: any): AxiosPromise<SecurityConfiguration> {
      return ApiResourceControllerApiFp(configuration).securityConfiguration4(options)(axios, basePath)
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    securityConfiguration5(options?: any): AxiosPromise<SecurityConfiguration> {
      return ApiResourceControllerApiFp(configuration).securityConfiguration5(options)(axios, basePath)
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    securityConfiguration6(options?: any): AxiosPromise<SecurityConfiguration> {
      return ApiResourceControllerApiFp(configuration).securityConfiguration6(options)(axios, basePath)
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    swaggerResources(options?: any): AxiosPromise<Array<SwaggerResource>> {
      return ApiResourceControllerApiFp(configuration).swaggerResources(options)(axios, basePath)
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    swaggerResources1(options?: any): AxiosPromise<Array<SwaggerResource>> {
      return ApiResourceControllerApiFp(configuration).swaggerResources1(options)(axios, basePath)
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    swaggerResources2(options?: any): AxiosPromise<Array<SwaggerResource>> {
      return ApiResourceControllerApiFp(configuration).swaggerResources2(options)(axios, basePath)
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    swaggerResources3(options?: any): AxiosPromise<Array<SwaggerResource>> {
      return ApiResourceControllerApiFp(configuration).swaggerResources3(options)(axios, basePath)
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    swaggerResources4(options?: any): AxiosPromise<Array<SwaggerResource>> {
      return ApiResourceControllerApiFp(configuration).swaggerResources4(options)(axios, basePath)
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    swaggerResources5(options?: any): AxiosPromise<Array<SwaggerResource>> {
      return ApiResourceControllerApiFp(configuration).swaggerResources5(options)(axios, basePath)
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    swaggerResources6(options?: any): AxiosPromise<Array<SwaggerResource>> {
      return ApiResourceControllerApiFp(configuration).swaggerResources6(options)(axios, basePath)
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    uiConfiguration(options?: any): AxiosPromise<UiConfiguration> {
      return ApiResourceControllerApiFp(configuration).uiConfiguration(options)(axios, basePath)
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    uiConfiguration1(options?: any): AxiosPromise<UiConfiguration> {
      return ApiResourceControllerApiFp(configuration).uiConfiguration1(options)(axios, basePath)
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    uiConfiguration2(options?: any): AxiosPromise<UiConfiguration> {
      return ApiResourceControllerApiFp(configuration).uiConfiguration2(options)(axios, basePath)
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    uiConfiguration3(options?: any): AxiosPromise<UiConfiguration> {
      return ApiResourceControllerApiFp(configuration).uiConfiguration3(options)(axios, basePath)
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    uiConfiguration4(options?: any): AxiosPromise<UiConfiguration> {
      return ApiResourceControllerApiFp(configuration).uiConfiguration4(options)(axios, basePath)
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    uiConfiguration5(options?: any): AxiosPromise<UiConfiguration> {
      return ApiResourceControllerApiFp(configuration).uiConfiguration5(options)(axios, basePath)
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    uiConfiguration6(options?: any): AxiosPromise<UiConfiguration> {
      return ApiResourceControllerApiFp(configuration).uiConfiguration6(options)(axios, basePath)
    },
  }
}

/**
 * ApiResourceControllerApi - object-oriented interface
 * @export
 * @class ApiResourceControllerApi
 * @extends {BaseAPI}
 */
export class ApiResourceControllerApi extends BaseAPI {
  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiResourceControllerApi
   */
  public securityConfiguration(options?: any) {
    return ApiResourceControllerApiFp(this.configuration).securityConfiguration(options)(this.axios, this.basePath)
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiResourceControllerApi
   */
  public securityConfiguration1(options?: any) {
    return ApiResourceControllerApiFp(this.configuration).securityConfiguration1(options)(this.axios, this.basePath)
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiResourceControllerApi
   */
  public securityConfiguration2(options?: any) {
    return ApiResourceControllerApiFp(this.configuration).securityConfiguration2(options)(this.axios, this.basePath)
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiResourceControllerApi
   */
  public securityConfiguration3(options?: any) {
    return ApiResourceControllerApiFp(this.configuration).securityConfiguration3(options)(this.axios, this.basePath)
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiResourceControllerApi
   */
  public securityConfiguration4(options?: any) {
    return ApiResourceControllerApiFp(this.configuration).securityConfiguration4(options)(this.axios, this.basePath)
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiResourceControllerApi
   */
  public securityConfiguration5(options?: any) {
    return ApiResourceControllerApiFp(this.configuration).securityConfiguration5(options)(this.axios, this.basePath)
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiResourceControllerApi
   */
  public securityConfiguration6(options?: any) {
    return ApiResourceControllerApiFp(this.configuration).securityConfiguration6(options)(this.axios, this.basePath)
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiResourceControllerApi
   */
  public swaggerResources(options?: any) {
    return ApiResourceControllerApiFp(this.configuration).swaggerResources(options)(this.axios, this.basePath)
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiResourceControllerApi
   */
  public swaggerResources1(options?: any) {
    return ApiResourceControllerApiFp(this.configuration).swaggerResources1(options)(this.axios, this.basePath)
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiResourceControllerApi
   */
  public swaggerResources2(options?: any) {
    return ApiResourceControllerApiFp(this.configuration).swaggerResources2(options)(this.axios, this.basePath)
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiResourceControllerApi
   */
  public swaggerResources3(options?: any) {
    return ApiResourceControllerApiFp(this.configuration).swaggerResources3(options)(this.axios, this.basePath)
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiResourceControllerApi
   */
  public swaggerResources4(options?: any) {
    return ApiResourceControllerApiFp(this.configuration).swaggerResources4(options)(this.axios, this.basePath)
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiResourceControllerApi
   */
  public swaggerResources5(options?: any) {
    return ApiResourceControllerApiFp(this.configuration).swaggerResources5(options)(this.axios, this.basePath)
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiResourceControllerApi
   */
  public swaggerResources6(options?: any) {
    return ApiResourceControllerApiFp(this.configuration).swaggerResources6(options)(this.axios, this.basePath)
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiResourceControllerApi
   */
  public uiConfiguration(options?: any) {
    return ApiResourceControllerApiFp(this.configuration).uiConfiguration(options)(this.axios, this.basePath)
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiResourceControllerApi
   */
  public uiConfiguration1(options?: any) {
    return ApiResourceControllerApiFp(this.configuration).uiConfiguration1(options)(this.axios, this.basePath)
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiResourceControllerApi
   */
  public uiConfiguration2(options?: any) {
    return ApiResourceControllerApiFp(this.configuration).uiConfiguration2(options)(this.axios, this.basePath)
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiResourceControllerApi
   */
  public uiConfiguration3(options?: any) {
    return ApiResourceControllerApiFp(this.configuration).uiConfiguration3(options)(this.axios, this.basePath)
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiResourceControllerApi
   */
  public uiConfiguration4(options?: any) {
    return ApiResourceControllerApiFp(this.configuration).uiConfiguration4(options)(this.axios, this.basePath)
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiResourceControllerApi
   */
  public uiConfiguration5(options?: any) {
    return ApiResourceControllerApiFp(this.configuration).uiConfiguration5(options)(this.axios, this.basePath)
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiResourceControllerApi
   */
  public uiConfiguration6(options?: any) {
    return ApiResourceControllerApiFp(this.configuration).uiConfiguration6(options)(this.axios, this.basePath)
  }
}

/**
 * CatalogManagerApi - axios parameter creator
 * @export
 */
export const CatalogManagerApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {'b' | 'anonymous'} zone
     * @param {string} requestId
     * @param {string} [name]
     * @param {boolean} [showHidden]
     * @param {boolean} [showPayees]
     * @param {boolean} [showHiddenPayees]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCategories(
      zone: 'b' | 'anonymous',
      requestId: string,
      name?: string,
      showHidden?: boolean,
      showPayees?: boolean,
      showHiddenPayees?: boolean,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'zone' is not null or undefined
      if (zone === null || zone === undefined) {
        throw new RequiredError('zone', 'Required parameter zone was null or undefined when calling getCategories.')
      }
      // verify required parameter 'requestId' is not null or undefined
      if (requestId === null || requestId === undefined) {
        throw new RequiredError(
          'requestId',
          'Required parameter requestId was null or undefined when calling getCategories.'
        )
      }
      const localVarPath = `/{zone}/catalog-manager/1.0/categories`.replace(
        `{${'zone'}}`,
        encodeURIComponent(String(zone))
      )
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (requestId !== undefined) {
        localVarQueryParameter['requestId'] = requestId
      }

      if (name !== undefined) {
        localVarQueryParameter['name'] = name
      }

      if (showHidden !== undefined) {
        localVarQueryParameter['showHidden'] = showHidden
      }

      if (showPayees !== undefined) {
        localVarQueryParameter['showPayees'] = showPayees
      }

      if (showHiddenPayees !== undefined) {
        localVarQueryParameter['showHiddenPayees'] = showHiddenPayees
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {'b' | 'anonymous'} zone
     * @param {string} requestId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCurrentVersion(zone: 'b' | 'anonymous', requestId: string, options: any = {}): RequestArgs {
      // verify required parameter 'zone' is not null or undefined
      if (zone === null || zone === undefined) {
        throw new RequiredError('zone', 'Required parameter zone was null or undefined when calling getCurrentVersion.')
      }
      // verify required parameter 'requestId' is not null or undefined
      if (requestId === null || requestId === undefined) {
        throw new RequiredError(
          'requestId',
          'Required parameter requestId was null or undefined when calling getCurrentVersion.'
        )
      }
      const localVarPath = `/{zone}/catalog-manager/1.0/current-version`.replace(
        `{${'zone'}}`,
        encodeURIComponent(String(zone))
      )
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (requestId !== undefined) {
        localVarQueryParameter['requestId'] = requestId
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {'b' | 'anonymous'} zone
     * @param {string} requestId
     * @param {string} [name]
     * @param {boolean} [showHidden]
     * @param {boolean} [showPayees]
     * @param {boolean} [showHiddenPayees]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getEcomCategories(
      zone: 'b' | 'anonymous',
      requestId: string,
      name?: string,
      showHidden?: boolean,
      showPayees?: boolean,
      showHiddenPayees?: boolean,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'zone' is not null or undefined
      if (zone === null || zone === undefined) {
        throw new RequiredError('zone', 'Required parameter zone was null or undefined when calling getEcomCategories.')
      }
      // verify required parameter 'requestId' is not null or undefined
      if (requestId === null || requestId === undefined) {
        throw new RequiredError(
          'requestId',
          'Required parameter requestId was null or undefined when calling getEcomCategories.'
        )
      }
      const localVarPath = `/{zone}/catalog-manager/1.0/ecom-categories`.replace(
        `{${'zone'}}`,
        encodeURIComponent(String(zone))
      )
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (requestId !== undefined) {
        localVarQueryParameter['requestId'] = requestId
      }

      if (name !== undefined) {
        localVarQueryParameter['name'] = name
      }

      if (showHidden !== undefined) {
        localVarQueryParameter['showHidden'] = showHidden
      }

      if (showPayees !== undefined) {
        localVarQueryParameter['showPayees'] = showPayees
      }

      if (showHiddenPayees !== undefined) {
        localVarQueryParameter['showHiddenPayees'] = showHiddenPayees
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {'b' | 'anonymous'} zone
     * @param {string} requestId
     * @param {string} [id]
     * @param {string} [categoryName]
     * @param {boolean} [showHidden]
     * @param {boolean} [showFromHiddenCategories]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getEcomPayees(
      zone: 'b' | 'anonymous',
      requestId: string,
      id?: string,
      categoryName?: string,
      showHidden?: boolean,
      showFromHiddenCategories?: boolean,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'zone' is not null or undefined
      if (zone === null || zone === undefined) {
        throw new RequiredError('zone', 'Required parameter zone was null or undefined when calling getEcomPayees.')
      }
      // verify required parameter 'requestId' is not null or undefined
      if (requestId === null || requestId === undefined) {
        throw new RequiredError(
          'requestId',
          'Required parameter requestId was null or undefined when calling getEcomPayees.'
        )
      }
      const localVarPath = `/{zone}/catalog-manager/1.0/ecom-payees`.replace(
        `{${'zone'}}`,
        encodeURIComponent(String(zone))
      )
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (requestId !== undefined) {
        localVarQueryParameter['requestId'] = requestId
      }

      if (id !== undefined) {
        localVarQueryParameter['id'] = id
      }

      if (categoryName !== undefined) {
        localVarQueryParameter['categoryName'] = categoryName
      }

      if (showHidden !== undefined) {
        localVarQueryParameter['showHidden'] = showHidden
      }

      if (showFromHiddenCategories !== undefined) {
        localVarQueryParameter['showFromHiddenCategories'] = showFromHiddenCategories
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {'b' | 'anonymous'} zone
     * @param {string} requestId
     * @param {string} [id]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getEnums(zone: 'b' | 'anonymous', requestId: string, id?: string, options: any = {}): RequestArgs {
      // verify required parameter 'zone' is not null or undefined
      if (zone === null || zone === undefined) {
        throw new RequiredError('zone', 'Required parameter zone was null or undefined when calling getEnums.')
      }
      // verify required parameter 'requestId' is not null or undefined
      if (requestId === null || requestId === undefined) {
        throw new RequiredError(
          'requestId',
          'Required parameter requestId was null or undefined when calling getEnums.'
        )
      }
      const localVarPath = `/{zone}/catalog-manager/1.0/enums`.replace(`{${'zone'}}`, encodeURIComponent(String(zone)))
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (requestId !== undefined) {
        localVarQueryParameter['requestId'] = requestId
      }

      if (id !== undefined) {
        localVarQueryParameter['id'] = id
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {'b' | 'anonymous'} zone
     * @param {string} requestId
     * @param {string} [id]
     * @param {string} [categoryName]
     * @param {boolean} [showHidden]
     * @param {boolean} [showFromHiddenCategories]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPayees(
      zone: 'b' | 'anonymous',
      requestId: string,
      id?: string,
      categoryName?: string,
      showHidden?: boolean,
      showFromHiddenCategories?: boolean,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'zone' is not null or undefined
      if (zone === null || zone === undefined) {
        throw new RequiredError('zone', 'Required parameter zone was null or undefined when calling getPayees.')
      }
      // verify required parameter 'requestId' is not null or undefined
      if (requestId === null || requestId === undefined) {
        throw new RequiredError(
          'requestId',
          'Required parameter requestId was null or undefined when calling getPayees.'
        )
      }
      const localVarPath = `/{zone}/catalog-manager/1.0/payees`.replace(`{${'zone'}}`, encodeURIComponent(String(zone)))
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (requestId !== undefined) {
        localVarQueryParameter['requestId'] = requestId
      }

      if (id !== undefined) {
        localVarQueryParameter['id'] = id
      }

      if (categoryName !== undefined) {
        localVarQueryParameter['categoryName'] = categoryName
      }

      if (showHidden !== undefined) {
        localVarQueryParameter['showHidden'] = showHidden
      }

      if (showFromHiddenCategories !== undefined) {
        localVarQueryParameter['showFromHiddenCategories'] = showFromHiddenCategories
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {'b' | 'anonymous'} zone
     * @param {string} requestId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPaymentSubscriptionPayees(zone: 'b' | 'anonymous', requestId: string, options: any = {}): RequestArgs {
      // verify required parameter 'zone' is not null or undefined
      if (zone === null || zone === undefined) {
        throw new RequiredError(
          'zone',
          'Required parameter zone was null or undefined when calling getPaymentSubscriptionPayees.'
        )
      }
      // verify required parameter 'requestId' is not null or undefined
      if (requestId === null || requestId === undefined) {
        throw new RequiredError(
          'requestId',
          'Required parameter requestId was null or undefined when calling getPaymentSubscriptionPayees.'
        )
      }
      const localVarPath = `/{zone}/catalog-manager/1.0/getPaymentSubscriptionPayees`.replace(
        `{${'zone'}}`,
        encodeURIComponent(String(zone))
      )
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (requestId !== undefined) {
        localVarQueryParameter['requestId'] = requestId
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {'b' | 'anonymous'} zone
     * @param {string} requestId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getRegions(zone: 'b' | 'anonymous', requestId: string, options: any = {}): RequestArgs {
      // verify required parameter 'zone' is not null or undefined
      if (zone === null || zone === undefined) {
        throw new RequiredError('zone', 'Required parameter zone was null or undefined when calling getRegions.')
      }
      // verify required parameter 'requestId' is not null or undefined
      if (requestId === null || requestId === undefined) {
        throw new RequiredError(
          'requestId',
          'Required parameter requestId was null or undefined when calling getRegions.'
        )
      }
      const localVarPath = `/{zone}/catalog-manager/1.0/regions`.replace(
        `{${'zone'}}`,
        encodeURIComponent(String(zone))
      )
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (requestId !== undefined) {
        localVarQueryParameter['requestId'] = requestId
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * CatalogManagerApi - functional programming interface
 * @export
 */
export const CatalogManagerApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {'b' | 'anonymous'} zone
     * @param {string} requestId
     * @param {string} [name]
     * @param {boolean} [showHidden]
     * @param {boolean} [showPayees]
     * @param {boolean} [showHiddenPayees]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCategories(
      zone: 'b' | 'anonymous',
      requestId: string,
      name?: string,
      showHidden?: boolean,
      showPayees?: boolean,
      showHiddenPayees?: boolean,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<CategoriesResponse> {
      const localVarAxiosArgs = CatalogManagerApiAxiosParamCreator(configuration).getCategories(
        zone,
        requestId,
        name,
        showHidden,
        showPayees,
        showHiddenPayees,
        options
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @param {'b' | 'anonymous'} zone
     * @param {string} requestId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCurrentVersion(
      zone: 'b' | 'anonymous',
      requestId: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<CurrentVersionResponse> {
      const localVarAxiosArgs = CatalogManagerApiAxiosParamCreator(configuration).getCurrentVersion(
        zone,
        requestId,
        options
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @param {'b' | 'anonymous'} zone
     * @param {string} requestId
     * @param {string} [name]
     * @param {boolean} [showHidden]
     * @param {boolean} [showPayees]
     * @param {boolean} [showHiddenPayees]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getEcomCategories(
      zone: 'b' | 'anonymous',
      requestId: string,
      name?: string,
      showHidden?: boolean,
      showPayees?: boolean,
      showHiddenPayees?: boolean,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<CategoriesResponse> {
      const localVarAxiosArgs = CatalogManagerApiAxiosParamCreator(configuration).getEcomCategories(
        zone,
        requestId,
        name,
        showHidden,
        showPayees,
        showHiddenPayees,
        options
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @param {'b' | 'anonymous'} zone
     * @param {string} requestId
     * @param {string} [id]
     * @param {string} [categoryName]
     * @param {boolean} [showHidden]
     * @param {boolean} [showFromHiddenCategories]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getEcomPayees(
      zone: 'b' | 'anonymous',
      requestId: string,
      id?: string,
      categoryName?: string,
      showHidden?: boolean,
      showFromHiddenCategories?: boolean,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<PayeesResponse> {
      const localVarAxiosArgs = CatalogManagerApiAxiosParamCreator(configuration).getEcomPayees(
        zone,
        requestId,
        id,
        categoryName,
        showHidden,
        showFromHiddenCategories,
        options
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @param {'b' | 'anonymous'} zone
     * @param {string} requestId
     * @param {string} [id]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getEnums(
      zone: 'b' | 'anonymous',
      requestId: string,
      id?: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<EnumsResponse> {
      const localVarAxiosArgs = CatalogManagerApiAxiosParamCreator(configuration).getEnums(zone, requestId, id, options)
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @param {'b' | 'anonymous'} zone
     * @param {string} requestId
     * @param {string} [id]
     * @param {string} [categoryName]
     * @param {boolean} [showHidden]
     * @param {boolean} [showFromHiddenCategories]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPayees(
      zone: 'b' | 'anonymous',
      requestId: string,
      id?: string,
      categoryName?: string,
      showHidden?: boolean,
      showFromHiddenCategories?: boolean,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<PayeesResponse> {
      const localVarAxiosArgs = CatalogManagerApiAxiosParamCreator(configuration).getPayees(
        zone,
        requestId,
        id,
        categoryName,
        showHidden,
        showFromHiddenCategories,
        options
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @param {'b' | 'anonymous'} zone
     * @param {string} requestId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPaymentSubscriptionPayees(
      zone: 'b' | 'anonymous',
      requestId: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaymentSubscriptionPayeesResponse> {
      const localVarAxiosArgs = CatalogManagerApiAxiosParamCreator(configuration).getPaymentSubscriptionPayees(
        zone,
        requestId,
        options
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @param {'b' | 'anonymous'} zone
     * @param {string} requestId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getRegions(
      zone: 'b' | 'anonymous',
      requestId: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<RegionsResponse> {
      const localVarAxiosArgs = CatalogManagerApiAxiosParamCreator(configuration).getRegions(zone, requestId, options)
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
  }
}

/**
 * CatalogManagerApi - factory interface
 * @export
 */
export const CatalogManagerApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  return {
    /**
     *
     * @param {'b' | 'anonymous'} zone
     * @param {string} requestId
     * @param {string} [name]
     * @param {boolean} [showHidden]
     * @param {boolean} [showPayees]
     * @param {boolean} [showHiddenPayees]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCategories(
      zone: 'b' | 'anonymous',
      requestId: string,
      name?: string,
      showHidden?: boolean,
      showPayees?: boolean,
      showHiddenPayees?: boolean,
      options?: any
    ): AxiosPromise<CategoriesResponse> {
      return CatalogManagerApiFp(configuration).getCategories(
        zone,
        requestId,
        name,
        showHidden,
        showPayees,
        showHiddenPayees,
        options
      )(axios, basePath)
    },
    /**
     *
     * @param {'b' | 'anonymous'} zone
     * @param {string} requestId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCurrentVersion(zone: 'b' | 'anonymous', requestId: string, options?: any): AxiosPromise<CurrentVersionResponse> {
      return CatalogManagerApiFp(configuration).getCurrentVersion(zone, requestId, options)(axios, basePath)
    },
    /**
     *
     * @param {'b' | 'anonymous'} zone
     * @param {string} requestId
     * @param {string} [name]
     * @param {boolean} [showHidden]
     * @param {boolean} [showPayees]
     * @param {boolean} [showHiddenPayees]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getEcomCategories(
      zone: 'b' | 'anonymous',
      requestId: string,
      name?: string,
      showHidden?: boolean,
      showPayees?: boolean,
      showHiddenPayees?: boolean,
      options?: any
    ): AxiosPromise<CategoriesResponse> {
      return CatalogManagerApiFp(configuration).getEcomCategories(
        zone,
        requestId,
        name,
        showHidden,
        showPayees,
        showHiddenPayees,
        options
      )(axios, basePath)
    },
    /**
     *
     * @param {'b' | 'anonymous'} zone
     * @param {string} requestId
     * @param {string} [id]
     * @param {string} [categoryName]
     * @param {boolean} [showHidden]
     * @param {boolean} [showFromHiddenCategories]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getEcomPayees(
      zone: 'b' | 'anonymous',
      requestId: string,
      id?: string,
      categoryName?: string,
      showHidden?: boolean,
      showFromHiddenCategories?: boolean,
      options?: any
    ): AxiosPromise<PayeesResponse> {
      return CatalogManagerApiFp(configuration).getEcomPayees(
        zone,
        requestId,
        id,
        categoryName,
        showHidden,
        showFromHiddenCategories,
        options
      )(axios, basePath)
    },
    /**
     *
     * @param {'b' | 'anonymous'} zone
     * @param {string} requestId
     * @param {string} [id]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getEnums(zone: 'b' | 'anonymous', requestId: string, id?: string, options?: any): AxiosPromise<EnumsResponse> {
      return CatalogManagerApiFp(configuration).getEnums(zone, requestId, id, options)(axios, basePath)
    },
    /**
     *
     * @param {'b' | 'anonymous'} zone
     * @param {string} requestId
     * @param {string} [id]
     * @param {string} [categoryName]
     * @param {boolean} [showHidden]
     * @param {boolean} [showFromHiddenCategories]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPayees(
      zone: 'b' | 'anonymous',
      requestId: string,
      id?: string,
      categoryName?: string,
      showHidden?: boolean,
      showFromHiddenCategories?: boolean,
      options?: any
    ): AxiosPromise<PayeesResponse> {
      return CatalogManagerApiFp(configuration).getPayees(
        zone,
        requestId,
        id,
        categoryName,
        showHidden,
        showFromHiddenCategories,
        options
      )(axios, basePath)
    },
    /**
     *
     * @param {'b' | 'anonymous'} zone
     * @param {string} requestId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPaymentSubscriptionPayees(
      zone: 'b' | 'anonymous',
      requestId: string,
      options?: any
    ): AxiosPromise<PaymentSubscriptionPayeesResponse> {
      return CatalogManagerApiFp(configuration).getPaymentSubscriptionPayees(zone, requestId, options)(axios, basePath)
    },
    /**
     *
     * @param {'b' | 'anonymous'} zone
     * @param {string} requestId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getRegions(zone: 'b' | 'anonymous', requestId: string, options?: any): AxiosPromise<RegionsResponse> {
      return CatalogManagerApiFp(configuration).getRegions(zone, requestId, options)(axios, basePath)
    },
  }
}

/**
 * CatalogManagerApi - object-oriented interface
 * @export
 * @class CatalogManagerApi
 * @extends {BaseAPI}
 */
export class CatalogManagerApi extends BaseAPI {
  /**
   *
   * @param {'b' | 'anonymous'} zone
   * @param {string} requestId
   * @param {string} [name]
   * @param {boolean} [showHidden]
   * @param {boolean} [showPayees]
   * @param {boolean} [showHiddenPayees]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CatalogManagerApi
   */
  public getCategories(
    zone: 'b' | 'anonymous',
    requestId: string,
    name?: string,
    showHidden?: boolean,
    showPayees?: boolean,
    showHiddenPayees?: boolean,
    options?: any
  ) {
    return CatalogManagerApiFp(this.configuration).getCategories(
      zone,
      requestId,
      name,
      showHidden,
      showPayees,
      showHiddenPayees,
      options
    )(this.axios, this.basePath)
  }

  /**
   *
   * @param {'b' | 'anonymous'} zone
   * @param {string} requestId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CatalogManagerApi
   */
  public getCurrentVersion(zone: 'b' | 'anonymous', requestId: string, options?: any) {
    return CatalogManagerApiFp(this.configuration).getCurrentVersion(
      zone,
      requestId,
      options
    )(this.axios, this.basePath)
  }

  /**
   *
   * @param {'b' | 'anonymous'} zone
   * @param {string} requestId
   * @param {string} [name]
   * @param {boolean} [showHidden]
   * @param {boolean} [showPayees]
   * @param {boolean} [showHiddenPayees]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CatalogManagerApi
   */
  public getEcomCategories(
    zone: 'b' | 'anonymous',
    requestId: string,
    name?: string,
    showHidden?: boolean,
    showPayees?: boolean,
    showHiddenPayees?: boolean,
    options?: any
  ) {
    return CatalogManagerApiFp(this.configuration).getEcomCategories(
      zone,
      requestId,
      name,
      showHidden,
      showPayees,
      showHiddenPayees,
      options
    )(this.axios, this.basePath)
  }

  /**
   *
   * @param {'b' | 'anonymous'} zone
   * @param {string} requestId
   * @param {string} [id]
   * @param {string} [categoryName]
   * @param {boolean} [showHidden]
   * @param {boolean} [showFromHiddenCategories]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CatalogManagerApi
   */
  public getEcomPayees(
    zone: 'b' | 'anonymous',
    requestId: string,
    id?: string,
    categoryName?: string,
    showHidden?: boolean,
    showFromHiddenCategories?: boolean,
    options?: any
  ) {
    return CatalogManagerApiFp(this.configuration).getEcomPayees(
      zone,
      requestId,
      id,
      categoryName,
      showHidden,
      showFromHiddenCategories,
      options
    )(this.axios, this.basePath)
  }

  /**
   *
   * @param {'b' | 'anonymous'} zone
   * @param {string} requestId
   * @param {string} [id]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CatalogManagerApi
   */
  public getEnums(zone: 'b' | 'anonymous', requestId: string, id?: string, options?: any) {
    return CatalogManagerApiFp(this.configuration).getEnums(zone, requestId, id, options)(this.axios, this.basePath)
  }

  /**
   *
   * @param {'b' | 'anonymous'} zone
   * @param {string} requestId
   * @param {string} [id]
   * @param {string} [categoryName]
   * @param {boolean} [showHidden]
   * @param {boolean} [showFromHiddenCategories]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CatalogManagerApi
   */
  public getPayees(
    zone: 'b' | 'anonymous',
    requestId: string,
    id?: string,
    categoryName?: string,
    showHidden?: boolean,
    showFromHiddenCategories?: boolean,
    options?: any
  ) {
    return CatalogManagerApiFp(this.configuration).getPayees(
      zone,
      requestId,
      id,
      categoryName,
      showHidden,
      showFromHiddenCategories,
      options
    )(this.axios, this.basePath)
  }

  /**
   *
   * @param {'b' | 'anonymous'} zone
   * @param {string} requestId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CatalogManagerApi
   */
  public getPaymentSubscriptionPayees(zone: 'b' | 'anonymous', requestId: string, options?: any) {
    return CatalogManagerApiFp(this.configuration).getPaymentSubscriptionPayees(
      zone,
      requestId,
      options
    )(this.axios, this.basePath)
  }

  /**
   *
   * @param {'b' | 'anonymous'} zone
   * @param {string} requestId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CatalogManagerApi
   */
  public getRegions(zone: 'b' | 'anonymous', requestId: string, options?: any) {
    return CatalogManagerApiFp(this.configuration).getRegions(zone, requestId, options)(this.axios, this.basePath)
  }
}
