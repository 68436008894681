import React, { FC } from 'react';
import Image from 'next/image';
import { Text } from '@mtsbank/ui-kit';
import { CATEGORIES_PATH, SPA } from '@root/constants';
import { categoryCardClickGtm } from '@root/utils/gtm/tsp/events';
import { Category } from '@open-api/ump/catalog-manager';
import { ItemIconWrapper, ItemWrapper, ListItemsWrapper } from '../styled';
import { Loading } from '../Loading';
import { CategoryAutoComplete } from './CategoryAutoComplete/CategoryAutoComplete';
import { useGetCategories } from '../../swr';
import { getIconByCategoryName } from '../../helpers';
import { Link } from '../../styled';
export const Payments: FC = () => {
  const {
    categories,
    isValidating
  } = useGetCategories();
  const handleLinkClick = (category: Category) => {
    categoryCardClickGtm(category.title);
  };
  return <>
      <CategoryAutoComplete />

      {isValidating ? <Loading /> : <ListItemsWrapper>
          {categories?.map((category, i) => {
        const categoryIcon = getIconByCategoryName(category.name);
        return <Link onClick={() => handleLinkClick(category)} href={`${SPA}/${CATEGORIES_PATH}/${category?.name}`} key={i}>
                <ItemWrapper>
                  <ItemIconWrapper>
                    <Image src={`https://www.mtsbank.ru/icons/${categoryIcon}`} width={24} height={24} />
                  </ItemIconWrapper>

                  <Text fontWeight="medium">{category.title}</Text>
                </ItemWrapper>
              </Link>;
      })}
        </ListItemsWrapper>}
    </>;
};