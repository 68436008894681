import React, { FC, useMemo } from 'react';
import { Icon, Text } from '@mtsbank/ui-kit';
import { SPA } from '@root/constants';
import { authHelper } from '@utils/authHelper/AuthHelper';
import { zaRubezhButtonClick } from '@components/Abroad/utils/events';
import { ItemIconWrapper, ItemWrapper, ListItemsWrapper } from '../styled';
import { Link } from '../../styled';
export const Transfers: FC = () => {
  const {
    isAuthenticated
  } = authHelper;
  const isAuth = isAuthenticated();
  const categoriesTransfer = useMemo(() => [{
    icon: 'payment/sbp',
    title: 'По номеру телефона',
    path: `${SPA}/transfer/sbp`
  }, {
    icon: 'baseX24/bankCard',
    title: 'По номеру карты',
    path: `/transfer/CardToCard`
  }, {
    icon: 'baseX24/ic-sync',
    title: 'Между своими счетами',
    path: `/transfer/self`
  }, {
    icon: 'baseX24/ic-planet',
    title: 'За рубеж',
    path: `${SPA}/transfer/abroad`,
    onClick: () => zaRubezhButtonClick()
  }, {
    icon: 'baseX24/ic-file',
    title: 'По реквизитам',
    path: isAuth ? `/transfer/requisites` : `${SPA}/categories/freepayment`
  }], [isAuth]);
  return <ListItemsWrapper>
      {categoriesTransfer.map((category, i) => <Link href={category.path} key={i} onClick={category.onClick}>
          <ItemWrapper>
            <ItemIconWrapper>
              <Icon loaderSize={44} icon={category.icon} width={20} />
            </ItemIconWrapper>
            <Text fontWeight="medium">{category.title}</Text>
          </ItemWrapper>
        </Link>)}
    </ListItemsWrapper>;
};