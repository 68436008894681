import { SPA } from '@root/constants'

const categoryIcons = [
  { categoryName: 'mobilianyi_telefon', icon: 'baseX24/iphone.svg' },
  { categoryName: 'comeback', icon: 'baseX24/games.svg' },
  { categoryName: 'kvartplata', icon: 'baseX24/bath.svg' },
  { categoryName: 'gosuslugi', icon: 'baseX24/flag.svg' },
  { categoryName: 'internet_and_tv', icon: 'baseX24/ic-rounded-monitor.svg' },
  { categoryName: 'kredity', icon: 'baseX24/discount.svg' },
  { categoryName: 'uslugiSNG', icon: 'baseX24/ic-planet.svg' },
  { categoryName: 'freepayment', icon: 'baseX24/press.svg' },
  { categoryName: 'uslugiSNG1', icon: 'baseX24/hotspot.svg' },
  { categoryName: 'emoney', icon: 'baseX24/coins.svg' },
  { categoryName: 'obrazovanie', icon: 'baseX24/ic-education.svg' },
  { categoryName: 'transport', icon: 'baseX24/bus.svg' },
  { categoryName: 'games', icon: 'baseX24/games.svg' },
  { categoryName: 'ishop', icon: 'baseX24/shoppingCart.svg' },
  { categoryName: 'travel', icon: 'baseX24/plane.svg' },
  { categoryName: 'strakhovanie', icon: 'baseX24/shield-check.svg' },
  { categoryName: 'security', icon: 'baseX24/video-review.svg' },
  { categoryName: 'blagotvoritelnost', icon: 'baseX24/heart-add.svg' },
  { categoryName: 'raznoe', icon: 'baseX24/more.svg' },
]

export const getIconByCategoryName = (cn: string) => categoryIcons?.find((item) => item.categoryName === cn)?.icon

export const getRouteTSPPath = (serviceId: string) => `${SPA}/pay/${serviceId}`
