import { PaymentsAndTransfersTabs } from '@root/components/Categories/PaymentsAndTransfers/PaymentsAndTransfers'
import {
  actionGroups,
  eventActions,
  eventCategories,
  eventLabels,
  events,
  screenNames,
  sendGmtEvent,
} from '../baseFunctions'
import { translit } from '../translit'

// Просмотр разводящей страницы +
export const showCategoriesPagesGtm = () =>
  sendGmtEvent({
    event: events.mtsPageview,
    screenName: screenNames.categoriesService,
    actionGroup: actionGroups.nonInteractions,
    eventCategory: eventCategories.categories,
    eventAction: eventActions.screenShow,
    eventLabel: eventLabels.razvodyaschayaStranica,
  })

// Клик на ТСП +
export const tspCardClickGtm = (serviceId: string) =>
  sendGmtEvent({
    event: events.mtsPlatezhiPerevody,
    screenName: getPaymentScreenName(serviceId),
    actionGroup: actionGroups.interactions,
    eventCategory: eventCategories.categories,
    eventAction: eventActions.buttonClick,
    eventLabel: eventLabels.tsp,
  })

// Клик по кнопке "оплатить" +
export const paymentButtonClickGtm = (serviceId: string) =>
  sendGmtEvent({
    event: events.mtsPlatezhiPerevody,
    eventCategory: eventCategories.paymentForm,
    eventAction: eventActions.buttonClick,
    eventLabel: eventLabels.oplatit,
    screenName: getPaymentScreenName(serviceId),
    actionGroup: actionGroups.interactions,
  })

// Заполнение полей +
export const fieldsFocusBlurGtm = (serviceId: string, fieldName: string) =>
  sendGmtEvent({
    event: events.mtsPlatezhiPerevody,
    eventCategory: eventCategories.paymentForm,
    eventAction: eventActions.formAdd,
    eventLabel: eventLabels.zapolneniPoleiFormy,
    screenName: getPaymentScreenName(serviceId),
    eventContext: translit(fieldName),
    actionGroup: actionGroups.interactions,
  })

// переход на 3ds +
export const show3dsGtm = (serviceId: string) =>
  sendGmtEvent({
    event: events.mtsPlatezhiPerevody,
    eventCategory: eventCategories.paymentForm,
    eventAction: eventActions.elementShow,
    eventLabel: eventLabels.podtverzhdeniePlatezhaЗDS,
    screenName: getPaymentScreenName(serviceId),
    actionGroup: actionGroups.nonInteractions,
  })

// Появление экрана платеж совершен успешно +
export const showSuccessScreenGtm = (serviceId: string) =>
  sendGmtEvent({
    event: events.mtsPlatezhiPerevody,
    eventCategory: eventCategories.paymentForm,
    eventAction: eventActions.confirmed,
    eventLabel: eventLabels.platezhOtpravlen,
    screenName: getPaymentScreenName(serviceId),
    actionGroup: actionGroups.nonInteractions,
  })

// Появление сообщения "Ошибка платежа" +
export const showPaymentErrorGtm = (serviceId: string) =>
  sendGmtEvent({
    event: events.mtsPlatezhiPerevody,
    eventCategory: eventCategories.paymentForm,
    eventAction: eventActions.rejected,
    eventLabel: eventLabels.platezhNeVypolnen,
    screenName: getPaymentScreenName(serviceId),
    actionGroup: actionGroups.nonInteractions,
  })

// Клик по иконкам в блоке на экране успеха (создать шаблон, подключить автоплатеж, повторить платеж, скачать чек)
export const actionButtonClickGtm = (serviceId: string) =>
  sendGmtEvent({
    event: events.mtsPlatezhiPerevody,
    eventCategory: eventCategories.paymentForm,
    eventAction: eventActions.elementClick,
    eventLabel: eventLabels.ikonkiDeistvii,
    screenName: getPaymentScreenName(serviceId),
    actionGroup: actionGroups.interactions,
  })

// Показ страницы категории +
export const showCategoryPageGtm = (categoryId: string, categoryName: string) =>
  sendGmtEvent({
    event: events.mtsPageview,
    eventCategory: eventCategories.categories,
    eventAction: eventActions.screenShow,
    eventLabel: eventLabels.kategoriya,
    screenName: getCategoryScreenName(categoryId),
    eventContext: translit(categoryName),
    actionGroup: actionGroups.nonInteractions,
  })

// Показ страницы тсп +
export const showPayeePageGtm = (serviceId: string, tspName: string) =>
  sendGmtEvent({
    event: events.mtsPageview,
    eventCategory: eventCategories.categories,
    eventAction: eventActions.screenShow,
    eventLabel: eventLabels.tsp,
    screenName: getPaymentScreenName(serviceId),
    eventContext: translit(tspName),
    actionGroup: actionGroups.nonInteractions,
  })

// Клик по табу "платежи/переводы" на разводящей +
export const tabClickGtm = (tab: PaymentsAndTransfersTabs) =>
  sendGmtEvent({
    event: events.mtsPlatezhiPerevody,
    eventCategory: eventCategories.categories,
    eventAction: eventActions.elementClick,
    eventLabel: tab === PaymentsAndTransfersTabs.PAYMENTS ? eventLabels.platezhi : eventLabels.perevody,
    screenName: screenNames.categoriesService,
    actionGroup: actionGroups.interactions,
  })

// Клик по карточке с категорией +
export const categoryCardClickGtm = (categoryName: string) =>
  sendGmtEvent({
    event: events.mtsPlatezhiPerevody,
    eventCategory: eventCategories.categories,
    eventAction: eventActions.buttonClick,
    eventLabel: eventLabels.tsp,
    screenName: screenNames.categoriesService,
    eventContent: translit(categoryName),
    actionGroup: actionGroups.interactions,
  })

// Клик по виджету "Проверьте и оплатите счета" +
export const checkChargesClickGtm = () =>
  sendGmtEvent({
    event: events.mtsPlatezhiPerevody,
    eventCategory: eventCategories.categories,
    eventAction: eventActions.elementClick,
    eventLabel: eventLabels.proveritOplaitScheta,
    screenName: screenNames.categoriesService,
    actionGroup: actionGroups.interactions,
  })

// Клик по иконкам в виджете Шаблоны и автоплатежи +
export const templatesCardClickGtm = (tspName: string) =>
  sendGmtEvent({
    event: events.mtsPlatezhiPerevody,
    eventCategory: eventCategories.categories,
    eventAction: eventActions.buttonClick,
    eventLabel: eventLabels.shablonyAvtoplatezhi,
    screenName: screenNames.categoriesService,
    eventContent: translit(tspName),
    actionGroup: actionGroups.interactions,
  })

// Клик по тсп из поиска +
export const searchCardClickGtm = (tspName: string) =>
  sendGmtEvent({
    event: events.mtsPlatezhiPerevody,
    eventCategory: eventCategories.categories,
    eventAction: eventActions.buttonClick,
    eventLabel: eventLabels.tsp,
    screenName: screenNames.categoriesService,
    eventContent: translit(tspName),
    actionGroup: actionGroups.interactions,
  })

// Клик по pdf +
export const pdfClickGtm = (docName: string) =>
  sendGmtEvent({
    event: events.mtsPlatezhiPerevody,
    eventCategory: eventCategories.categories,
    eventAction: eventActions.buttonClick,
    eventLabel: translit(docName),
    screenName: screenNames.categoriesService,
    actionGroup: actionGroups.interactions,
  })

export const bannerClickGtm = (bannerName: string) =>
  sendGmtEvent({
    event: events.mtsPlatezhiPerevody,
    eventCategory: eventCategories.categories,
    eventAction: eventActions.bannerClick,
    eventLabel: bannerName,
    actionGroup: actionGroups.interactions,
  })

export const bannerShowGtm = (bannerName: string) =>
  sendGmtEvent({
    event: events.mtsPlatezhiPerevody,
    eventCategory: eventCategories.categories,
    eventAction: eventActions.bannerShow,
    eventLabel: bannerName,
    actionGroup: actionGroups.nonInteractions,
  })

const getPaymentScreenName = (serviceId: string) =>
  serviceId ? screenNames.payServiceId(serviceId) : screenNames.payPhone

const getCategoryScreenName = (categoryId: string) => screenNames.categoryServiceId(categoryId)
