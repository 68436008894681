import useSWR from 'swr'
import { AxiosError } from 'axios'

import { useEffect, useState } from 'react'
import { CategoriesResponse, PayeesResponse } from '@open-api/ump/catalog-manager'
import { Templates } from '@open-api/ump/templates'
import { catalogManagerApi } from '@api/ump/catalog-manager'
import { templatesApi } from '@api/ump/templates'
import { authHelper } from '@utils/authHelper/AuthHelper'
import { uuid } from '@utils/generateUuid'
import { getPcekZone } from '../CardToCard/utils/helpers'

const anonymousHeaders = {
  headers: { 'client-Id': 'mts-money-web-mtsid' },
}

export const useGetPayees = () => {
  const fetcher = async (_, zone: 'b' | 'anonymous'): Promise<PayeesResponse> => {
    const response = await catalogManagerApi.getPayees(
      zone,
      uuid(),
      undefined,
      undefined,
      undefined,
      undefined,
      zone === 'anonymous' ? anonymousHeaders : undefined
    )

    return response?.data
  }

  const {
    data: { payees = null } = {},
    error,
    ...rest
  } = useSWR<PayeesResponse, AxiosError>(['/catalog-manager/1.0/payees', getPcekZone()], fetcher, {
    revalidateOnFocus: false,
    revalidateIfStale: false,
    shouldRetryOnError: false,
  })

  return {
    payees,
    error,
    isLoading: !error && payees === null,
    ...rest,
  }
}

export const useGetTemplates = () => {
  const [templates, setTemplates] = useState<Templates[]>([])
  const [error, setError] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [uId, setUid] = useState(authHelper?.userProfile?.userId)

  const fetcher = async (ud: string) => {
    setIsLoading(true)

    const response = await templatesApi.getTemplates({ userId: ud as unknown as number })

    if (response?.status !== 200) {
      setError(true)

      return
    }

    if (response?.data.templates.length) {
      setTemplates(response?.data.templates)
    }

    setIsLoading(false)
  }

  useEffect(() => {
    const interval = setInterval(() => {
      const userId = authHelper?.userProfile?.userId

      setUid(userId)

      if (userId) {
        clearInterval(interval)
      }
    }, 500)

    return () => clearInterval(interval)
  }, [])

  useEffect(() => {
    if (uId) {
      fetcher(uId)
    }
  }, [uId])

  return {
    templates,
    error,
    isLoading,
  }
}

export const useGetCategories = () => {
  const fetcher = async (_, zone: 'b' | 'anonymous'): Promise<CategoriesResponse> => {
    const response = await catalogManagerApi.getCategories(
      zone,
      uuid(),
      undefined,
      undefined,
      undefined,
      undefined,
      zone === 'anonymous' ? anonymousHeaders : undefined
    )

    return response?.data
  }

  const {
    data: { categories = null } = {},
    error,
    ...rest
  } = useSWR<CategoriesResponse, AxiosError>(['/catalog-manager/1.0/categories', getPcekZone()], fetcher, {
    revalidateOnFocus: false,
    revalidateIfStale: false,
    shouldRetryOnError: false,
  })

  return {
    categories,
    error,
    isLoading: !error && categories === null,
    ...rest,
  }
}
